var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.routeTo)?_c('button',{staticClass:"group/icon-btn focus-visible-outline btn-xs btn rounded !bg-transparent",class:{
    'no-animation !m-0 !border-0 !p-0 focus-visible:!rounded-[1px] focus-visible:!outline-1 focus-visible:!outline-focus':
      _vm.transparent
  },attrs:{"disabled":_vm.disabled},on:{"click":(evt) => _setup.emit('click', evt),"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();$event.preventDefault();return ((evt) => _setup.emit('click', evt)).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.stopPropagation();$event.preventDefault();return ((evt) => _setup.emit('click', evt)).apply(null, arguments)}]}},[_vm._t("default")],2):_c('router-link',{staticClass:"focus-visible-outline btn-xs btn rounded border-0 border-none !bg-transparent",class:{
    'no-animation !m-0 !border-0 !p-0 focus-visible:!rounded-[1px] focus-visible:!outline-1 focus-visible:!outline-focus':
      _vm.transparent
  },attrs:{"to":_vm.routeTo,"disabled":_vm.disabled}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }