<template>
  <div
    class="sidebar-wrapper sidebar-index t-0 b-0 l-0 fixed flex h-full min-h-54 flex-col border-l border-dark1/100 bg-white"
    :class="{
      'sidebar-wrapper-collapsed': isCollapse && !isMobile,
      'sidebar-wrapper-mobile': isMobile && !isOpened,
      'sidebar-wrapper-open': isMobile && isOpened,
      '!transition-none': isMobileSwitching
    }"
    style="margin-top: var(--temp-scroll-margin)"
  >
    <div
      class="relative flex h-12.5 min-h-12.5 items-center justify-center overflow-hidden border-y"
      :class="{
        'border-black/100 bg-black/100': isEditing,
        'border-dark1/100 bg-white/100': !isEditing,
        'border-r': isMobile
      }"
    >
      <PostAnimateShowing class="absolute" :show="isCollapse" hide-slower>
        <RouteLink
          to="/"
          class="flex justify-center"
          :inverted="isEditing"
          use-link
          is-simple
          :class="`svg-override${isEditing ? '-inverted' : ''}`"
        >
          <BlooBloomLogo />
        </RouteLink>
      </PostAnimateShowing>
      <PostAnimateShowing class="absolute" :show="!isCollapse" hide-slower>
        <RouteLink
          to="/"
          class="flex justify-center"
          :inverted="isEditing"
          use-link
          is-simple
          :class="`svg-override${isEditing ? '-inverted' : ''}`"
        >
          <AdminLogo />
        </RouteLink>
      </PostAnimateShowing>
    </div>
    <ScrollingContainer
      class="flex-1 border-r border-dark1/100 pt-4.5"
      scroller-class="sidebar-scrollbar overflow-x-hidden pb-4.5"
      :css-height="`max(calc(100dvh - ${HEADER_HEIGHT}px - 80px), 86px)`"
    >
      <ul role="menu" class="pr-with-scroll-sm text-body-l-reg flex flex-col pl-sm">
        <SidebarItem
          v-for="route in mainRoutes"
          :key="route.path"
          :item="route"
          :popup-top-shift="HEADER_HEIGHT - 8"
          :base-path="route.path"
          :is-collapse="isCollapse"
          :is-opened="isOpened"
          @toggle="handleItemToggle"
        />
      </ul>
    </ScrollingContainer>
    <div class="min-h-20 overflow-hidden border-r border-b border-dark1/100">
      <ul role="menu" class="text-body-l-reg flex flex-col px-sm pt-xs pb-sm">
        <SidebarItem
          v-for="route in bottomRoutes"
          :key="route.path"
          :item="route"
          :popup-top-shift="-8"
          :base-path="route.path"
          :is-collapse="isCollapse"
          :is-opened="isOpened"
          @toggle="handleItemToggle"
        />
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import ScrollingContainer from '~/components/layout/ScrollingContainer.vue';
import PostAnimateShowing from '~/components/layout/PostAnimateShowing.vue';
import BlooBloomLogo from '~/components/icon/BlooBloomLogo.vue';
import AdminLogo from '~/components/icon/AdminLogo.vue';
import { getTrueRouteItem, RouteItem } from '~/components/sidebar/sidebarUtils';
import SidebarItem from '~/components/sidebar/SidebarItem.vue';
import RouteLink from '~/components/sidebar/RouteLink.vue';
import { fetchFulfilmentsStats } from '~/api/fulfillments';
import { FulfillmentStatuses, FulfillmentTypes, FulfilmentsStats } from '~/api/schema/fulfillmentConstants';
import { fetchLensOrdersStats } from '~/api/lensOrders';
import { LensOrdersStats } from '~/api/schema/lensOrders';
import { HEADER_HEIGHT } from './layoutConstants';

// Cyclic import dependencies resolving via dynamic import
const useStore = ref<Function>();
import('@/store').then((res) => {
  useStore.value = res.default;
});

enum RouteNamesWithStats {
  FULFILMENT_ROUTE_VALUE = '/fullfilments',
  LENS_ORDERS_ROUTE_VALUE = '/lens-orders'
}

const FETCH_STATS_INTERVAL = 5000;
const ANIMATION_DURATION = 300;
const statsInitialStateValue: Record<
  RouteNamesWithStats,
  { loading?: boolean; stats?: FulfilmentsStats | LensOrdersStats; interval?: number }
> = {
  [RouteNamesWithStats.FULFILMENT_ROUTE_VALUE]: { loading: false },
  [RouteNamesWithStats.LENS_ORDERS_ROUTE_VALUE]: { loading: false }
};

const store = computed(() => useStore.value && useStore.value());

const openedItems = ref<Array<string>>([]);

const routeStatsStates = ref(statsInitialStateValue);

const fulfillmentsStats = ref<FulfilmentsStats>();
const lensOrdersStats = ref<LensOrdersStats>();
const isMobileSwitching = ref(false);

const sidebar = computed(() => store.value?.getters['sidebar']);
const isMobile = computed(() => store.value?.getters['device'] === 'mobile');
const isOpened = computed(() => sidebar.value?.opened);
const isCollapse = computed(() => !isOpened.value && !isMobile.value);
const routes = computed(() => store.value?.getters['permission_routes'] as Array<RouteItem>);
const isEditing = computed(() => store.value?.getters['navBarEditing']);
const bottomRoutes = computed(() => routes.value?.filter((route) => getTrueRouteItem(route).meta?.isBottom));

const mainRoutes = computed(
  (): Array<RouteItem> =>
    routes.value?.reduce((finalRoutes, route) => {
      const routeItem = getTrueRouteItem(route);
      if ((Object.values(RouteNamesWithStats) as string[]).includes(route.path)) {
        updateChildrenTitle(route);
      }
      if (!routeItem.hidden && !routeItem.meta?.isBottom) {
        return [...finalRoutes, route];
      }
      return finalRoutes;
    }, [] as Array<RouteItem>) || []
);

function updateChildrenTitle(route: RouteItem) {
  if (route?.children?.length) {
    let amounts: Record<string, number> | undefined;
    switch (route.path) {
      case RouteNamesWithStats.FULFILMENT_ROUTE_VALUE:
        amounts = fulfillmentsStats.value?.[FulfillmentStatuses.PENDING].types_breakdown;
        break;
      case RouteNamesWithStats.LENS_ORDERS_ROUTE_VALUE:
        amounts = Object.fromEntries(
          Object.entries(lensOrdersStats.value || {}).map(([key, value]) => [key, value.total])
        );
        break;
    }
    if (amounts) {
      route.children = route.children.map((childRoute) => {
        let amount: number | undefined;
        switch (route.path) {
          case RouteNamesWithStats.FULFILMENT_ROUTE_VALUE:
            amount = childRoute?.meta?.fulfilmentTypes?.reduce(
              (count: number, type: FulfillmentTypes) => (type in amounts ? count + amounts[type] : count),
              0
            );
            break;
          case RouteNamesWithStats.LENS_ORDERS_ROUTE_VALUE:
            amount = childRoute?.meta?.type ? amounts[childRoute?.meta?.type || ''] || 0 : undefined;
            break;
        }
        const amountText = amount === undefined ? '' : ` (${amount})`;
        const updatedTitle = `${childRoute?.meta?.title}${amountText}` || '';
        return {
          ...childRoute,
          meta: {
            ...childRoute?.meta,
            updatedTitle
          }
        };
      });
    }
  }
}

async function setStats(routeName: RouteNamesWithStats) {
  try {
    routeStatsStates.value[routeName].loading = true;
    switch (routeName) {
      case RouteNamesWithStats.FULFILMENT_ROUTE_VALUE: {
        const { data } = await fetchFulfilmentsStats();
        fulfillmentsStats.value = data.stats;
        break;
      }
      case RouteNamesWithStats.LENS_ORDERS_ROUTE_VALUE: {
        const { data } = await fetchLensOrdersStats();
        lensOrdersStats.value = data.stats;
        break;
      }
    }
    routeStatsStates.value[routeName].loading = false;
  } catch (error) {
    console.log(error);
    routeStatsStates.value[routeName].loading = false;
  }
}

async function updateStats() {
  if (window) {
    const routeNamesWithStatsArray = Object.values(RouteNamesWithStats);
    const openedStatsRoutes = openedItems.value.filter((openedItem) =>
      routeNamesWithStatsArray.includes(openedItem as RouteNamesWithStats)
    ) as RouteNamesWithStats[];
    openedStatsRoutes.forEach((route) => {
      if (!routeStatsStates.value[route]?.interval && !routeStatsStates.value[route]?.loading) {
        setStats(route);
        routeStatsStates.value[route].interval = window.setInterval(() => setStats(route), FETCH_STATS_INTERVAL);
      }
    });

    const closedStatsRoutes = routeNamesWithStatsArray.filter((route) => !openedItems.value.includes(route));
    closedStatsRoutes.forEach((route) => {
      if (routeStatsStates.value[route].interval) {
        window.clearInterval(routeStatsStates.value[route].interval);
        routeStatsStates.value[route].interval = undefined;
      }
    });
  }
}

watch(isOpened, (val) => {
  if (!val) {
    openedItems.value = [];
  }
});
function handleItemToggle(path: string, isOpen: boolean) {
  if (isOpen) {
    openedItems.value = [...openedItems.value, path];
  } else {
    openedItems.value = openedItems.value.filter((item) => item !== path);
  }
}
// temporary disable animations when switching between mobile/non-mobile to avoid extra screen flickering
watch(isMobile, () => {
  isMobileSwitching.value = true;
  setTimeout(() => (isMobileSwitching.value = false), ANIMATION_DURATION);
});

watch(openedItems, async () => {
  await updateStats();
});
</script>
<style lang="scss">
.sidebar-wrapper {
  max-width: $sideBarWidth;
  min-width: $sideBarWidth;
  transition: width 0.29s;
  transition-property: width, max-width, min-width, transform;

  &-collapsed {
    max-width: $sideBarWidthCollapsed;
    min-width: $sideBarWidthCollapsed;
  }

  &-mobile {
    position: fixed;
    pointer-events: none;
    transform: translate3d(-$sideBarWidth, 0, 0);
  }

  &-open {
    position: fixed;
  }
}
/*
Add padding for scroll bar by adding invisible border at the top and bottom of the scrolling thumb
*/
.sidebar-scrollbar {
  &::-webkit-scrollbar-thumb {
    @apply rounded bg-light2 bg-clip-padding;
    border-top: 10px solid rgba(0, 0, 0, 0);
    border-bottom: 28px solid rgba(0, 0, 0, 0);
    min-height: 58px;
  }
}
</style>
