const mutations = {
  setFromRoute: (state, fromRoute) => {
    state.fromRoute = fromRoute;
  },
  setIsFromNewTab: (state, value) => {
    state.isFromNewTab = value;
  },
  // use this flag to suppress "from" route saving (changing) - in case, for example, when just query parameters changed
  setSuppressFromRouteChange: (state, value) => {
    state.suppressFromRouteChange = value;
  }
};

const actions = {};

const getters = {
  fromRoute: (state) => state.fromRoute,
  isFromNewTab: (state) => state.isFromNewTab,
  setSuppressFromRouteChange: (state) => state.setSuppressFromRouteChange
};

export default {
  namespaced: true,
  state: {
    fromRoute: null,
    isFromNewTab: true,
    suppressFromRouteChange: false
  },
  mutations,
  actions,
  getters
};
