<template>
  <li
    v-if="isShown && (displayedItem.meta?.title || displayedItem.meta?.updatedTitle || displayedItem.meta?.icon)"
    ref="menuItemRef"
    class="cursor-pointer"
    :class="{
      'pt-2xs': !nested,
      'pb-2xs': !nested && !showSubmenu,
      'pb-[5px]': !nested && showSubmenu,
      'py-[5px] first:pt-2xs': nested && !small,
      'mb-px pt-1 first:pt-2xs': nested && small,
      'max-w-5': isCollapse
    }"
    role="menuitem"
  >
    <RouteLink
      ref="menuLinkRef"
      :to="link"
      :is-disabled="isDisabled"
      :is-category="isCategory"
      :allow-tab="(isOpened || isCollapse) && (!nested || !collapsed)"
      class="min-w-5 px-0.5"
      :class="[
        linkClasses,
        {
          'focus-inside-rounded': showFocus,
          'mx-2': !small,
          'mx-px': small
        }
      ]"
      :inner-class="linkInnerClasses"
      @click.native.capture="(event) => handleMenuClick(false, event)"
      @mouseover.native="handleMenuPopupDisplay(false)"
      @mouseout.native="handleMenuPopupHide"
      @mousedown.native="showFocus = false"
      @blur="showFocus = true"
    >
      <SidebarIcon v-if="!nested && displayedItem.meta?.icon" :icon="displayedItem.meta?.icon" />
      <div v-else />
      <PostAnimateShowing
        :show="!isCollapse || nested"
        :class="{ 'col-span-2 overflow-hidden text-ellipsis': !!displayedItem.meta?.updatedTitle }"
      >
        <span
          class="whitespace-nowrap"
          :class="{
            'text-body-l-reg': !nested,
            'text-body-l-light': nested
          }"
        >
          {{ displayedItem.meta?.updatedTitle || displayedItem.meta?.title }}
        </span>
      </PostAnimateShowing>
      <PostAnimateShowing v-if="isCategory" :show="!isCollapse || nested" class="flex">
        <!-- hidden button to focus category which has no links - to focus properly -->
        <IconButton
          class="relative !max-h-0 !min-h-0 max-w-0 !p-0 !outline-0 focus-visible:!outline-0"
          :class="{ '-left-25': isCollapse }"
          tabindex="0"
          @focus.native="handleMenuClick(true)"
          @focusout.native="checkClosePopup"
          @blur.native="showFocus = true"
        />
        <SubmenuArrow
          :direction="showSubmenu ? Directions.UP : Directions.DOWN"
          :class="{
            'h-[5px] w-2': small
          }"
        />
      </PostAnimateShowing>
    </RouteLink>
    <ul
      v-if="isCategory && !isCollapseChanging && !isCollapse"
      class="sidebar-menu-in overflow-hidden bg-white pl-0"
      :style="{
        maxHeight: showSubmenu && children ? `${children.length * SUBMENU_ITEM_HEIGHT + 10}px` : '0'
      }"
    >
      <SidebarItem
        v-for="(child, index) in children"
        :key="child.path"
        nested
        :collapsed="!showSubmenu"
        :item="child"
        :small="small"
        :base-path="resolvePath(child.path, basePath)"
        :is-opened="isOpened"
        @focusout.native="(event) => index === children.length - 1 && checkClosePopup(event)"
        @click="(event, link) => emit('click', event, link)"
      />
    </ul>
    <ul
      v-if="isCategory && !isCollapseChanging && isCollapse"
      ref="popupRef"
      class="sidebar-menu-popup absolute left-[81px] z-[2001] min-w-[153px] origin-[left_18px] rounded bg-white shadow-dropdown"
      :class="{
        'scale-100 opacity-100': showPopup,
        'scale-0 opacity-0': !showPopup
      }"
      @mouseover="handleMenuPopupDisplay(false)"
      @mouseout="handleMenuPopupHide"
      @click="handlePopupClick"
    >
      <PopupItem
        v-for="(child, index) in children"
        :key="child.path"
        :collapsed="!showPopup"
        :item="child"
        :base-path="resolvePath(child.path, basePath)"
        @focusout.native="(event) => index === children.length - 1 && checkClosePopup(event)"
      />
    </ul>
    <div
      v-if="!isCategory && !props.nested && isCollapse"
      class="sidebar-menu-toast pointer-events-none absolute left-[81px] z-[2001] min-w-[153px] rounded bg-dark1 py-2xs px-xs text-body-xs text-white/100 opacity-0"
      :style="{ top: `${popupTop}px` }"
      :class="{ 'opacity-100': showPopup }"
    >
      {{ displayedItem.meta?.title }}
    </div>
  </li>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useRoute } from 'vue-router/composables';
import { Store } from 'vuex';

import { CSSClass } from '~/api/schema/common';
import PostAnimateShowing from '~/components/layout/PostAnimateShowing.vue';
import IconButton from '~/components/button/IconButton.vue';
import SubmenuArrow from '~/components/icon/sidebar/SubmenuArrow.vue';
import { Directions } from '~/components/icon/constants';
import PopupItem from './PopupItem.vue';
import RouteLink from './RouteLink.vue';
import SidebarIcon from './SidebarIcon.vue';
import { getTrueRouteItem, resolvePath, RouteItem, RouteLinkType } from './sidebarUtils';

// Cyclic import dependencies resolving via dynamic import
const store = ref<Store<any>>();
// @ts-ignore
import('@/store').then((res) => {
  store.value = res.default();
});

const SUBMENU_ITEM_HEIGHT = 30;
// timeout after which popup may start hiding after mouse is out
const SUBMENU_HIDING_TIMEOUT = 300;
// overall time of a single animation
const MENU_ANIMATION_TIME = 300;

const route = useRoute();

const props = withDefaults(
  defineProps<{
    item: RouteItem;
    popupTopShift?: number;
    nested?: boolean;
    collapsed?: boolean;
    basePath?: string;
    // sidebar is visible and collapsed
    isCollapse?: boolean;
    // sidebar is visible and full width
    isOpened?: boolean;
    openSubmenu?: boolean;
    linkInnerClasses?: CSSClass;
    small?: boolean;
  }>(),
  {
    nested: false,
    basePath: '',
    popupTopShift: 0,
    linkInnerClasses: '!grid grid-cols-[26px_auto_12px] items-center no-underline hover:no-underline'
  }
);

const emit = defineEmits<{
  (e: 'toggle', path: string, isOpened: boolean);
  (e: 'click', event?: Event, link?: RouteLinkType);
}>();

const displayedItem = computed(() => getTrueRouteItem(props.item, route));

const children = computed(() => displayedItem.value.children?.filter((child) => !getTrueRouteItem(child).hidden));
const isCategory = computed(() => !!children.value?.length);
const isShown = computed(
  () =>
    !displayedItem.value.hidden ||
    (displayedItem.value.hidden &&
      displayedItem.value.meta?.showOnlyOnActiveMenu &&
      displayedItem.value.meta.activeMenu &&
      route.path.includes(displayedItem.value.meta.activeMenu))
);
// to show focus by keyboard only, we set this to false on mouse clicks and restore to true when focus left
const showFocus = ref(true);

const showSubmenu = ref(false);
const isHiding = ref(false);

const handleMenuClick = (override?: boolean, event?: Event) => {
  if (isCategory.value) {
    showFocus.value = true;
    if (props.isOpened) {
      showSubmenu.value = override || !showSubmenu.value;
      emit('toggle', props.item.path, showSubmenu.value);
    } else if (props.isCollapse) {
      handleMenuPopupDisplay(true, override);
    }
  } else {
    emit('click', event, link.value);
  }
};

const handlePopupClick = () => {
  // prevent re-opening by mouse over for duration of animation because we must close
  isHiding.value = true;
  setTimeout(() => (isHiding.value = false), MENU_ANIMATION_TIME);
};

const showPopup = ref(false);
const showPopupByClick = ref(false);
const menuLinkRef = ref<HTMLElement | null>(null);
const menuItemRef = ref<HTMLElement | null>(null);
const popupRef = ref<HTMLElement | null>(null);
const hideTimeout = ref<NodeJS.Timeout | null>(null);

const popupTop = ref(0);

const calculatePopupTop = () => {
  // TODO: account scrolling!
  if (menuItemRef.value) {
    const rect = menuItemRef.value.getBoundingClientRect();
    // when we are inside the scroller container, measure container causes bounding rect calculated relatively
    // it, so we need to adjust to the global by providing this shift
    popupTop.value = rect.top - (props.popupTopShift || 0);
    if (popupRef.value) {
      popupRef.value.style.top = `${popupTop.value}px`;
    }
  }
};

const handleMenuPopupDisplay = (byClick: boolean, override?: boolean) => {
  if (!props.isCollapse || isHiding.value) {
    return;
  }
  if (hideTimeout.value) {
    clearTimeout(hideTimeout.value);
    hideTimeout.value = null;
  }
  if (showPopupByClick.value) {
    if (byClick && !override) {
      // toggle
      doHidePopup();
    }
  } else {
    if (isCategory.value) {
      // ignore mouse movement when toggled by click - for mobile clicks
      showPopupByClick.value = byClick;
    }
    calculatePopupTop();
    if (!showPopup.value) {
      emit('toggle', props.item.path, true);
    }
    showPopup.value = true;
  }
};

const handleMenuPopupHide = () => {
  if (!props.isCollapse) {
    return;
  }
  if (!showPopupByClick.value && !hideTimeout.value) {
    hideTimeout.value = setTimeout(doHidePopup, SUBMENU_HIDING_TIMEOUT);
  }
};

const doHidePopup = () => {
  showPopupByClick.value = false;
  if (showPopup.value) {
    emit('toggle', props.item.path, false);
  }
  showPopup.value = false;
  if (hideTimeout.value) {
    clearTimeout(hideTimeout.value);
    hideTimeout.value = null;
  }
};

onClickOutside(menuLinkRef as any, doHidePopup);

const checkClosePopup = (event: FocusEvent) => {
  // when we lose focus on the main item, and focus is not going to be in the popup, close the popup - we go out
  let current = (event.relatedTarget as HTMLElement)?.parentElement;
  while (current && current !== menuItemRef.value) {
    current = current.parentElement;
  }
  if (!current) {
    doHidePopup();
    if (!isLinkActive.value) {
      showSubmenu.value = false;
    }
  }
};

watch(
  () => props.isOpened,
  () => {
    showSubmenu.value = false;
    doHidePopup();
  }
);

const isCollapseChanging = ref(false);
watch(
  () => props.isCollapse,
  () => {
    // for the duration of the animations, disable inner animations for popups, so they would not appear
    isCollapseChanging.value = true;
    doHidePopup();
    setTimeout(() => (isCollapseChanging.value = false), MENU_ANIMATION_TIME);
  }
);

onMounted(() => {
  if (props.openSubmenu) {
    showSubmenu.value = true;
  }
});
watch(
  () => props.openSubmenu,
  () => (props.openSubmenu === undefined ? null : (showSubmenu.value = props.openSubmenu))
);

// Placeholder for future uses
const link = computed<RouteLinkType>(() =>
  displayedItem.value.meta?.queryParam
    ? {
        path: displayedItem.value.path,
        query: Object.fromEntries(
          Object.entries(displayedItem.value.query || {}).filter(
            ([key, _value]) => key === displayedItem.value.meta?.queryParam
          )
        )
      }
    : resolvePath(displayedItem.value.path, props.basePath, displayedItem.value.query)
);
const isDisabled = ref(false);
const isLinkActive = computed(() => {
  const currentFulfillmentType = store.value?.state.fulfillments?.fulfillmentType;
  return (
    (link.value !== '/' &&
      typeof link.value === 'string' &&
      (((route as RouteItem).meta?.activeMenu || '').startsWith(link.value) ||
        (route.path || route.fullPath).startsWith(link.value))) ||
    (props.item.meta?.activeMenu &&
      route.name === 'Individual fulfillment view' &&
      props.item.meta.fulfilmentTypes?.includes(currentFulfillmentType) &&
      (route.path || route.fullPath).startsWith(props.item.meta?.activeMenu)) ||
    (children.value &&
      children.value.some(
        (child) =>
          (route.path || route.fullPath).startsWith(child.path) ||
          (route.path || route.fullPath).startsWith(`/${props.basePath}/${child.path}`)
      )) ||
    (props.item.meta?.queryParam &&
      (route.query[props.item.meta?.queryParam] === props.item.path ||
        (!!props.item.children?.length && route.query[props.item.meta?.queryParam]?.includes(props.item.path))))
  );
});

const linkClasses = computed(
  () =>
    `fold-out-menu${props.nested ? '-terciary' : ''}${
      isDisabled.value ? '-disabled' : `${(isLinkActive.value && '-active') || ''}`
    }`
);
</script>
<script lang="ts">
export default {
  name: 'SidebarItem'
};
</script>
<style>
.sidebar-menu-in {
  transition: max-height 0.29s;
}
.sidebar-menu-popup {
  box-shadow: 0 8px 20px -4px rgba(23, 24, 24, 0.12), 0 3px 6px -3px rgba(23, 24, 24, 0.08);
  /* We have to specify below this way to avoid extra animations on other property changes, and
    transform animation property specifying requires all of the below to work correctly
  */
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
  transition-duration: 0.29s;
}
.sidebar-menu-toast {
  transition: opacity 0.29s;
}
</style>
