const stateData = () => ({
  fulfillment: null,
  fulfillmentType: undefined,
  cachedFulfillment: null,
  cachedFulfillmentTimestamp: 0,
  selectedHomeTrialStatus: null
});

// max milliseconds in cache - we cache fulfillments only to avoid duplicate API requests, so this value should not be large
const MAX_FULFILLMENT_CACHE_TIME = 5000;

const getters = {
  cachedFulfillment: (state) => {
    if (state.cachedFulfillment) {
      if (Date.now() - state.cachedFulfillmentTimestamp < MAX_FULFILLMENT_CACHE_TIME) {
        return state.cachedFulfillment;
      }
      state.cachedFulfillment = null;
    }
    return null;
  }
};

const actions = {};

const mutations = {
  setFulfillment: (state, fulfillment) => {
    state.fulfillment = fulfillment;
  },
  setCurrentFulfillmentType: (state, fulfillmentType) => {
    state.fulfillmentType = fulfillmentType;
  },
  setCachedFulfillment: (state, fulfillment) => {
    state.cachedFulfillment = fulfillment;
    // pre-caution for case user closes browser before the moment when the cache is used and cleared
    state.cachedFulfillmentTimestamp = fulfillment ? Date.now() : 0;
  },
  setSelectedHomeTrialStatus: (state, status) => {
    state.selectedHomeTrialStatus = status;
  }
};

export default {
  namespaced: true,
  state: stateData,
  mutations,
  getters,
  actions
};
