export enum FulfillmentTypes {
  WEB_PURCHASES = 'glasses_delivery_to_customer',
  STORE_PURCHASES = 'glasses_pick_up',
  REGLAZING = 'reglaze',
  EDITING = 'edit',
  WARRANTY_EXCHANGE_REQUEST = 'warranty_exchange_request',
  HOME_TRIAL = 'home_trial',
  EYE_TEST = 'eye_test',
  FRAME_REPLACEMENT = 'frame_replacement',
  ACCESSORY = 'accessory',
  GIFT_CARD = 'gift_card'
}

export const specialServicesFulfillmentTypes = [
  FulfillmentTypes.REGLAZING,
  FulfillmentTypes.EDITING,
  FulfillmentTypes.WARRANTY_EXCHANGE_REQUEST
];
export const webPurchasesFulfillmentTypes = [FulfillmentTypes.WEB_PURCHASES];
export const storePurchasesFulfillmentTypes = [FulfillmentTypes.STORE_PURCHASES];
export const htoPurchasesFulfillmentTypes = [FulfillmentTypes.HOME_TRIAL];
export const accessoriesFulfillmentTypes = [FulfillmentTypes.ACCESSORY];

// no EYE_TEST or GIFT_CARD pages exist for now
export const fulfillmentRouteByTypeMap = {
  [FulfillmentTypes.WEB_PURCHASES]: 'home-deliveries',
  [FulfillmentTypes.STORE_PURCHASES]: 'store-collections',
  [FulfillmentTypes.HOME_TRIAL]: 'home-trial-orders',
  [FulfillmentTypes.ACCESSORY]: 'accessories',
  [FulfillmentTypes.REGLAZING]: 'reglazing',
  [FulfillmentTypes.EDITING]: 'edits',
  [FulfillmentTypes.WARRANTY_EXCHANGE_REQUEST]: 'warranty',
  [FulfillmentTypes.FRAME_REPLACEMENT]: 'edits'
};

export const fulfillmentTypeShortUILabel = {
  [FulfillmentTypes.WEB_PURCHASES]: 'Customer',
  [FulfillmentTypes.STORE_PURCHASES]: 'Store',
  [FulfillmentTypes.HOME_TRIAL]: 'HTO',
  [FulfillmentTypes.REGLAZING]: 'Reglaze',
  [FulfillmentTypes.EDITING]: 'Edit',
  [FulfillmentTypes.WARRANTY_EXCHANGE_REQUEST]: 'Warranty',
  [FulfillmentTypes.ACCESSORY]: 'Accessory'
};

export enum FulfillmentStatuses {
  PENDING = 'pending',
  PROCESSING = 'processing',
  ON_HOLD = 'on_hold',
  TO_BE_GLAZED = 'to_be_glazed',
  GLAZING = 'glazing',
  DISPATCHING = 'dispatching',
  PRINTING_LABELS = 'printing_labels',
  DISPATCHED = 'dispatched',
  CANCELED = 'canceled',
  MULTIPLE = 'multiple',
  BLOCKED = 'blocked',
  RETURNED = 'returned',
  PARTIALLY_RESTOCKED = 'partially_restocked',
  EXPIRED = 'expired',
  WAY_BACK = 'way_back',
  NOT_DELIVERED = 'not_delivered',
  DESTOCKED = 'destocked',
  WARRANTY_REJECTED = 'warranty_rejected',
  EMAILED = 'emailed',
  TO_BE_EMAILED = 'to_be_emailed'
}

export const fulfilmentStatusTextForMessage = {
  [FulfillmentStatuses.DISPATCHED]: 'dispatched',
  [FulfillmentStatuses.ON_HOLD]: 'On hold',
  [FulfillmentStatuses.CANCELED]: 'cancelled'
};

export enum FulfillmentWarrantyStatuses {
  AWAITING = 'awaiting',
  REJECTED = 'rejected',
  APPROVED = 'approved'
}

export enum FulfillmentMissingElementTypes {
  PRESCRIPTION = 'prescription',
  SEND_IT_LATER = 'send_it_later',
  PAYMENT = 'payment',
  PUPILLARY_DISTANCE = 'pupillary_distance',
  FRAMES = 'frames',
  LENSES = 'lenses',
  FRAMES_AND_LENSES = 'frames_and_lenses',
  AWAITING_RETURN = 'awaiting_return',
  EYE_TEST_BOOKED = 'eye_test_booked',
  BLOCKING = 'blocking',
  GLAZING = 'glazing',
  CHECKING = 'checking',
  OPTICIAN_AI = 'optician_ai'
}

export const glazingMissingElements = [
  FulfillmentMissingElementTypes.BLOCKING,
  FulfillmentMissingElementTypes.GLAZING,
  FulfillmentMissingElementTypes.CHECKING
];

export enum FulfillmentDeliveryTypes {
  DELIVERY_TO_CUSTOMER = 'delivery_to_customer',
  PICK_UP = 'pick_up',
  SERVICE = 'service',
  NONE = 'none'
}

export enum FulfillmentAddressType {
  COLLECTION = 'Collection address',
  SHIPPING = 'Shipping address',
  SERVICE = 'Service address'
}

export const DEFAULT_ADDRESS_TYPE_TITLE = 'Shipping address';
export const addressTypeByFulfillmentDeliveryType = {
  [FulfillmentDeliveryTypes.DELIVERY_TO_CUSTOMER]: FulfillmentAddressType.SHIPPING,
  [FulfillmentDeliveryTypes.PICK_UP]: FulfillmentAddressType.COLLECTION,
  [FulfillmentDeliveryTypes.SERVICE]: FulfillmentAddressType.SERVICE,
  [FulfillmentDeliveryTypes.NONE]: FulfillmentAddressType.SHIPPING
};

export type FulfilmentsStats = Record<
  FulfillmentStatuses,
  { total: number; types_breakdown: Record<FulfillmentTypes | 'no_fulfillment_type', number> }
>;

export enum FulfillmentShippingMethods {
  STORE = 'Store collection',
  HOME = 'Home delivery'
}
