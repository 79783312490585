import axios from 'axios/index';
import Cookies from 'js-cookie';

export enum ApplicationType {
  POS = 'POS',
  ADMIN = 'ADMIN',
  MED = 'MED',
  WEB = 'WEB'
}

export enum PlatformType {
  POS = 'pos',
  HUB = 'hub',
  MED = 'med',
  WEB = 'web'
}

export default ApplicationType;

export const RequestHeaderApplicationTypes = {
  [ApplicationType.ADMIN]: ApplicationType.ADMIN.toLowerCase(),
  [ApplicationType.POS]: ApplicationType.POS.toLowerCase(),
  [ApplicationType.MED]: ApplicationType.MED
};

export function getApplicationType(): ApplicationType {
  return process.env.VUE_APP_TYPE as ApplicationType;
}

export function isPOSApplication(): boolean {
  return process.env.VUE_APP_TYPE === ApplicationType.POS;
}

export function isAdminApplication(): boolean {
  return process.env.VUE_APP_TYPE === ApplicationType.ADMIN;
}

export function isMEDApplication(): boolean {
  return process.env.VUE_APP_TYPE === ApplicationType.MED;
}

export function isWebApplication(): boolean {
  return process.env.VUE_APP_TYPE === ApplicationType.WEB;
}

export function getRequestHeaderPlatform() {
  return RequestHeaderApplicationTypes[process.env.VUE_APP_TYPE || ApplicationType.ADMIN];
}

export function isPROD(): boolean {
  return process.env.VUE_APP_ENV === 'production';
}

const VERSION_PATH = '/version.txt';
const VERSION_COOKIE_NAME = 'app_version';

export async function checkVersion() {
  try {
    const currentVersion = Cookies.get(VERSION_COOKIE_NAME);
    const { data } = await axios.get(`${VERSION_PATH}?t=${Date.now()}`);
    if (data.trim() !== currentVersion?.trim()) {
      Cookies.set(VERSION_COOKIE_NAME, data.trim());
      window.location.reload();
    }
  } catch (e) {
    console.error('Error fetching current app version');
    console.log(e);
  }
}

const CHECK_FOR_VERSION_INTERVAL = 5 * 60 * 1000; // 5 minutes = 5min * 60sec * 1000ms
let checkIntervalId: number | undefined;
let activityIntervalId: number | undefined;

export function setupCheckVersionTimer() {
  restartTimerOnActivity();
  restartCheckVersionTimer();
}

function restartTimerOnActivity() {
  if (window) {
    if (activityIntervalId) {
      window.clearInterval(activityIntervalId);
    }
    activityIntervalId = window.setInterval(() => {
      // @ts-ignore
      if (navigator?.userActivation?.isActive) {
        restartCheckVersionTimer();
      }
    }, 1000);
  }
}

function restartCheckVersionTimer() {
  if (window) {
    if (checkIntervalId) {
      window.clearInterval(checkIntervalId);
    }
    checkIntervalId = window.setInterval(checkVersion, CHECK_FOR_VERSION_INTERVAL);
  }
}
