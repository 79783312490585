//~~legacy~~
import Vue from 'vue';
import Vuex from 'vuex';
import { getIdbStoreData, setIdbStoreData, setIdbStoreHydrated } from '~/utils/store/persistIdbStore';
import notifications from '~/utils/store/notifications';
import getters from './getters';
import user from './modules/user';
import frames from './modules/frames';
import glass from './modules/glass';
import glassVariant from './modules/glassVariant';
import lens from './modules/lens';
import order from './modules/order';
import fulfillments from './modules/fulfillments';
import eyeTest from './modules/eyeTest';
import customer from './modules/customer';
import payment from './modules/payment';
import orderItem from './modules/orderItems';
import selectedOrderItem from './modules/orderItems';
import blog from './modules/blogs';
import permission from './modules/permission';
import app from './modules/app';
import tagsView from './modules/tagsView';
import settings from './modules/settings';
import editOrderPurchases from './modules/editOrderPurchases';
import orderCheckout from './modules/orderCheckout';
import editOrderHomeTrials from './modules/editOrderHomeTrials';
import orderAddGlass from './modules/orderAddGlass';
import orderDraft from './modules/orderDraft';
import orderDraftAddGlass from './modules/orderDraftAddGlass';
import orderDraftCheckout from './modules/orderDraftCheckout';
import orderDraftAddPrescription from './modules/orderDraftAddPrescription';
import orderDraftAddReglazing from './modules/orderDraftAddReglazing';
import editOrderDraft from './modules/editOrderDraft';
import orderDraftEditAddGlass from './modules/orderDraftEditAddGlass';
import orderDraftEditCheckout from './modules/orderDraftEditCheckout';
import orderDraftEditAddReglazing from './modules/orderDraftEditAddReglazing';
import orderDraftEditAddPrescription from './modules/orderDraftEditAddPrescription';
import pinCodes from './modules/pinCodes';
import auth from './modules/auth';
import orderDiscount from './modules/orderDiscount';
import routerState from './modules/routerState';

Vue.use(Vuex);

const PERSISTED_STORE_NAMES = [
  'frames',
  'lens',
  'payment',
  'order',
  'customer',
  'settings',
  'eyeTest',
  'glass',
  'fulfillments',
  'blog',
  'orderItem',
  'selectedGlassVariant',
  'selectedHTO',
  'glassVariant',
  'editOrderPurchases',
  'orderDraft',
  'orderDraftAddGlass',
  'orderDraftCheckout',
  'orderDraftAddPrescription',
  'orderDraftAddReglazing',
  'editOrderDraft',
  'orderDraftEditAddGlass',
  'orderDraftEditCheckout',
  'orderDraftEditAddReglazing',
  'orderDraftEditAddPrescription',
  'pinCodes',
  'auth',
  'routerState'
];

export const store = new Vuex.Store({
  modules: {
    tagsView,
    payment,
    user,
    frames,
    lens,
    order,
    customer,
    permission,
    app,
    settings,
    eyeTest,
    glass,
    fulfillments,
    blog,
    orderItem,
    selectedOrderItem,
    glassVariant,
    editOrderPurchases,
    orderCheckout,
    editOrderHomeTrials,
    orderAddGlass,
    orderDraft,
    orderDraftAddGlass,
    orderDraftCheckout,
    orderDraftAddPrescription,
    orderDraftAddReglazing,
    editOrderDraft,
    orderDraftEditAddGlass,
    orderDraftEditCheckout,
    orderDraftEditAddReglazing,
    orderDraftEditAddPrescription,
    pinCodes,
    auth,
    orderDiscount,
    routerState,
    notifications
  },
  getters,
  plugins: [
    async (store) => {
      //Connect passing store after each store mutation
      store.subscribe(
        (_mutation, state) => {
          setIdbStoreData(state, PERSISTED_STORE_NAMES);
        },
        { prepend: true }
      );

      //Migration function; TODO: should be removed in the future with the compression library and force param @131
      const storeStr = localStorage.getItem('vuex');
      if (storeStr) {
        try {
          const storeObj = JSON.parse(storeStr);
          await setIdbStoreData(storeObj, PERSISTED_STORE_NAMES, true);
          localStorage.removeItem('vuex');
        } catch (error) {
          console.error('Error while migrating localStorage store.', error);
        }
      }

      //Load saved store and if present replace existing values
      const storeData = await getIdbStoreData();
      if (storeData && Object.keys(storeData).length) {
        const merged = { ...store.state };
        for (const [key, value] of Object.entries(storeData)) {
          merged[key] = merged[key] ? { ...merged[key], ...value } : value;
        }
        store.replaceState(merged);
      }

      setIdbStoreHydrated(true);
    }
  ]
});

export default function useStore() {
  return store;
}
