import { Account } from './account';
import { Currency } from './currencies';
import { ISODate, ISODateTime } from './common';

export enum AnalyticsTypes {
  REVENUE = 'revenue',
  KPIS = 'kpis',
  RETAIL_STAFF = 'retail-staff',
  PRODUCT = 'product'
}

export const AnalyticsTypesTitlesMap = {
  [AnalyticsTypes.REVENUE]: 'Revenue',
  [AnalyticsTypes.KPIS]: 'KPIs',
  [AnalyticsTypes.RETAIL_STAFF]: 'Retail staff',
  [AnalyticsTypes.PRODUCT]: 'Product'
};

export enum RevenueSalesChannelDataKey {
  REVENUE_INCLUDING_VAT = 'revenue_including_vat',
  VAT = 'vat',
  VAT_PERCENTAGE = 'vat_percentage',
  GROSS_REVENUE = 'gross_revenue',
  REFUNDS = 'refunds',
  REFUNDS_PERCENTAGE = 'refunds_percentage',
  NET_REVENUE = 'net_revenue'
}

export enum StaffSalesChannelDataKey {
  GROSS_REVENUE = 'gross_revenue',
  REFUNDS = 'refunds',
  REFUNDS_PERCENTAGE = 'refunds_percentage',
  NET_REVENUE = 'net_revenue',
  PURCHASE_ORDERS = 'purchase_orders',
  PAIRS = 'pairs',
  PAIRS_PER_ORDER = 'pairs_per_order',
  AOV = 'aov',
  FOOTFALL_VISITORS = 'footfall_visitors',
  FOOTFALL_CONVERSION = 'footfall_conversion'
}

export const StaffSalesChannelDataKeyTitleMap: Record<StaffSalesChannelDataKey, string> = {
  [StaffSalesChannelDataKey.GROSS_REVENUE]: 'Gross revenue',
  [StaffSalesChannelDataKey.REFUNDS]: 'Refunds (%)',
  [StaffSalesChannelDataKey.NET_REVENUE]: 'Net revenue',
  [StaffSalesChannelDataKey.PURCHASE_ORDERS]: 'Orders',
  [StaffSalesChannelDataKey.PAIRS_PER_ORDER]: 'Pairs per order',
  [StaffSalesChannelDataKey.AOV]: 'AOV',
  [StaffSalesChannelDataKey.FOOTFALL_CONVERSION]: 'Conversion rate',
  [StaffSalesChannelDataKey.REFUNDS_PERCENTAGE]: 'Refunds Percentage',
  [StaffSalesChannelDataKey.PAIRS]: 'Pairs',
  [StaffSalesChannelDataKey.FOOTFALL_VISITORS]: 'Footfall Visitors'
};

export enum KPISalesChannelDataKey {
  FOOTFALL_OR_UNIQUE_WEBSITE_VISITORS = 'footfall_or_unique_website_visitors',
  FOOTFALL_OR_WEBSITE_CONVERSION = 'footfall_or_website_conversion',
  HOME_TRIAL_ORDERS = 'home_trial_oders',
  HOME_TRIAL_CONVERSION = 'home_trial_conversion',
  PURCHASE_ORDERS = 'purchase_orders',
  AOV = 'aov',
  PAIRS = 'pairs',
  PAIRS_PER_ORDER = 'pairs_per_order',
  NPS = 'nps',
  REPEATED_ORDERS_COUNT = 'repeated_orders_count',
  REPEATED_REVENUE_AMOUNT = 'repeated_revenue_amount',
  REPEAT_ORDERS_PERCENTAGE = 'repeat_orders_percentage',
  REPEAT_REVENUE_PERCENTAGE = 'repeat_revenue_percentage',
  TESTING_CAPACITY = 'testing_capacity',
  EYE_TESTS_BOOKED = 'eye_tests_booked',
  OCCUPANCY_RATE = 'occupancy_rate',
  NO_SHOWS_RATE = 'no_shows_rate',
  OTHER_NON_CONVERTIBLES_RATE = 'other_non_convertibles_rate',
  TESTING_CONVERSION_SPOT = 'testing_conversion_spot',
  TESTING_CONVERSION_MONTH = 'testing_conversion_-14_30_days',
  TESTING_CONVERSION_YEAR = 'testing_conversion_-14_365_days'
}

export enum ChapterProductKey {
  MODELS = 'models',
  COLOUR = 'colours',
  SKUS = 'skus',
  LENS_TYPES = 'lens_types',
  LENS_BRANDS = 'lens_brands',
  LENS_INDICES = 'lens_indices',
  LENS_COATINGS = 'lens_coatings'
}

export const ChapterProductTitlesMap: Record<ChapterProductKey, string> = {
  [ChapterProductKey.MODELS]: 'Model',
  [ChapterProductKey.COLOUR]: 'Colour',
  [ChapterProductKey.SKUS]: 'SKU',
  [ChapterProductKey.LENS_TYPES]: 'Lens type',
  [ChapterProductKey.LENS_BRANDS]: 'Lens brand',
  [ChapterProductKey.LENS_INDICES]: 'Lens indices',
  [ChapterProductKey.LENS_COATINGS]: 'Lens coating'
};
export enum ProductDataKey {
  AGE_IN_MONTHS = 'age_in_months',
  TOTAL_EXCLUDING_PYWC_CALCULATION = 'total_excluding_PYWC_calculation',
  FULLY_PRICED = 'fully_priced',
  PYWC_DISCOUNTED = 'PYWC_discounted',
  TOTAL = 'total'
}

export enum ProductSubDataKey {
  NUMBER_OF_PAIRS = 'number_of_pairs',
  AVERAGE_PRICE = 'average_price',
  REVENUE = 'revenue'
}

export const ProductDataKeyTitleMap: Record<ProductDataKey, string> = {
  [ProductDataKey.AGE_IN_MONTHS]: 'Age (months)',
  [ProductDataKey.TOTAL_EXCLUDING_PYWC_CALCULATION]: 'Total (excl. PWYC)',
  [ProductDataKey.FULLY_PRICED]: 'Fully priced',
  [ProductDataKey.PYWC_DISCOUNTED]: 'PYWC Discounted',
  [ProductDataKey.TOTAL]: 'Total'
};

export const ProductSubDataKeyTitleMap: Record<ProductSubDataKey, string> = {
  [ProductSubDataKey.NUMBER_OF_PAIRS]: 'Pairs sold (%)',
  [ProductSubDataKey.AVERAGE_PRICE]: 'Avg. price',
  [ProductSubDataKey.REVENUE]: 'Revenue (%)'
};

export enum SalesChannelSubDataKey {
  CONSOLIDATED = 'consolidated',
  TESTING = 'testing',
  NON_TESTING = 'non_testing'
}

export interface BaseCurrencyData {
  amount: number;
  currency_id: number;
  previous_amount?: number;
  change_percentage?: string;
}

export interface RevenueData {
  by_currency: string[];
  default_currency_data: BaseCurrencyData & { ratio?: string };
}

export interface AverageOrderValue {
  by_currency: string[];
  default_currency_data: BaseCurrencyData & { orders_count?: number; avg_order_items: number };
}

export interface FootfallData {
  current: {
    count: number;
    conversion: string;
  };
  previous: {
    count: number;
    conversion: string;
  };
  change_percentage: {
    count: string;
    conversion: string;
  };
}

export interface BaseTypesAnalyticsData {
  value: string;
  comparative_value: string;
  comparative_percentage: string;
}

export type SalesChannelAnalyticsData<T extends string> = {
  [key in SalesChannelSubDataKey]: Record<T, BaseTypesAnalyticsData>;
};

export interface AnalyticsPeriods {
  from: {
    date: ISODate;
    date_time: ISODateTime;
  };
  to: {
    date: ISODate;
    date_time: ISODateTime;
  };
}

export interface BaseAnalyticsAPIResponseData {
  periods: {
    current_period: AnalyticsPeriods;
    previous_period: AnalyticsPeriods;
  };
}

export interface RevenueAnalyticsData extends BaseAnalyticsAPIResponseData {
  sales_channels: Record<
    string,
    {
      configuration_name: string;
      name: string;
      data: SalesChannelAnalyticsData<RevenueSalesChannelDataKey>;
    }
  >;
  all_stores: {
    data: SalesChannelAnalyticsData<RevenueSalesChannelDataKey>;
  };
  total: {
    data: SalesChannelAnalyticsData<RevenueSalesChannelDataKey>;
  };
}

export interface KPIAnalyticsData extends BaseAnalyticsAPIResponseData {
  sales_channels: Record<
    string,
    {
      configuration_name: string;
      name: string;
      data: SalesChannelAnalyticsData<KPISalesChannelDataKey>;
    }
  >;
  all_stores: {
    data: SalesChannelAnalyticsData<KPISalesChannelDataKey>;
  };
  total: {
    data: SalesChannelAnalyticsData<KPISalesChannelDataKey>;
  };
}

export interface StaffSalesChannelAnalyticsData {
  configuration_name: string;
  name: string;
  staff: Record<
    string,
    {
      name: string;
      data: Record<StaffSalesChannelDataKey, BaseTypesAnalyticsData>;
    }
  >;
  total: {
    data: Record<StaffSalesChannelDataKey, BaseTypesAnalyticsData>;
  };
}
export interface StaffAnalyticsData extends BaseAnalyticsAPIResponseData {
  sales_channels: Record<string, StaffSalesChannelAnalyticsData>;
}

export interface ProductDataBase {
  [ProductDataKey.AGE_IN_MONTHS]: BaseTypesAnalyticsData;
  [ProductDataKey.TOTAL_EXCLUDING_PYWC_CALCULATION]: Record<ProductSubDataKey, BaseTypesAnalyticsData>;
  [ProductDataKey.FULLY_PRICED]: Record<ProductSubDataKey, BaseTypesAnalyticsData>;
  [ProductDataKey.PYWC_DISCOUNTED]: Record<ProductSubDataKey, BaseTypesAnalyticsData>;
  [ProductDataKey.TOTAL]: Record<ProductSubDataKey, BaseTypesAnalyticsData>;
}

export interface ProductsAnalyticsData extends BaseAnalyticsAPIResponseData {
  products: {
    [key in ChapterProductKey]: Record<string, ProductDataBase>;
  };
  total: ProductDataBase;
}

export interface BaseAnalyticsData {
  gross_revenue: RevenueData;
  refunds: RevenueData;
  net_revenue: RevenueData;
  average_order_value: AverageOrderValue;
}
export interface StaffAnalyticsItem extends BaseAnalyticsData {
  administrator: Account;
  footfall_conversion: string;
}

export interface StaffAnalyticsTotals extends BaseAnalyticsData {
  orders_count: number;
  avg_order_items: number;
}

export interface StaffAnalytics {
  staff: StaffAnalyticsItem[];
  totals: StaffAnalyticsTotals;
}

export interface StoreAnalyticsTotals extends BaseAnalyticsData {
  orders_count: {
    current: number;
    previous: number;
    change_percentage: string;
  };
  avg_order_items: {
    current: number;
    previous: number;
    change_percentage: string;
  };
  footfall_data: FootfallData;
}

export interface StoreAnalyticsItem extends StoreAnalyticsTotals {
  sales_channel: {
    id: number;
    name: string;
  };
}

export interface StoreAnalytics {
  stores: StoreAnalyticsItem[];
  totals: StoreAnalyticsTotals;
}

export interface Period {
  from: {
    date: string;
    date_time: string;
  };
  to: {
    date: string;
    date_time: string;
  };
}

export interface Periods {
  current_period: Period;
  previous_period: Period;
}

export interface AnalyticsData {
  staff_analytics: StaffAnalytics;
  store_analytics: StoreAnalytics;
  periods: Periods;
  currencies: Currency[];
}

export interface PeriodsParams {
  from?: string;
  to?: string;
  qualitative?: string;
}
export interface AnalyticsParams {
  filters: {
    period?: PeriodsParams;
    comparative_period?: PeriodsParams;
  };
  sales_channel_id?: number | string;
  currency_code?: string;
}
