export const EMAIL_VALIDATION_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEXP_DOUBLE_AS_STRING = /^[0-9.]+$/;
export const REGEXP_SIGNED_DOUBLE_AS_STRING = /^[0-9.+-]+$/;
export const REGEXP_SIGNED_DOUBLE_AND_BAL_AS_STRING = /^[0-9.+-]+$|^B$|^BA$|^BAL$/;
export const COMMON_NUMBER_ALLOW_PATTERN = '[0-9\\.]';
export const PHONE_ALLOW_PATTERN = '[0-9\\-\\+\\ ]';
export const INTEGER_ALLOW_PATTERN = '[0-9]';
export const ALPHABETIC_INTEGER_ALLOW_PATTERN = '[a-zA-Z0-9]';
export const NUMBER_WITH_THOUSANDS_ALLOW_PATTERN = '[0-9\\.\\,]';
export const FOUR_DIGIT_DATE_PATTERN = /^[0-9]{0,4}[./-]?[0-9]{0,2}[./-]?[0-9]{0,4}$/;
export const VALIDATION_150_YEARS_IN_DAYS = 150 * 365;

export const WEB_MIN_OPTIONS_FOR_DROPDOWN_SEARCH = 8;

export enum ValidationResultTypes {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  NONE = ''
}

export enum ValidatorTypes {
  REQUIRED = 'required',
  REQUIRED_UPLOAD = 'required_upload',
  MIN_LENGTH = 'minLength',
  MAX_LENGTH = 'maxLength',
  MIN = 'min',
  MAX = 'max',
  SAME_AS = 'sameAs',
  IN_ARRAY = 'inArray',
  EMAIL = 'email',
  DATE_PAST = 'date_past',
  DATE_STRICTLY_DAY_PAST = 'date_strictly_day_past',
  DATE_FUTURE = 'date_future',
  DATE_OLDER_THAN_X_DAYS = 'date_older_than_x_days',
  TIME_FORMAT_VALID = 'time_format_valid',
  REGEX_MATCH = 'regex_match',
  CUSTOM = 'custom'
}

export const formValidationClassMap: Record<ValidationResultTypes, string> = {
  [ValidationResultTypes.ERROR]: `validation-${ValidationResultTypes.ERROR}`,
  [ValidationResultTypes.WARNING]: `validation-${ValidationResultTypes.WARNING}`,
  [ValidationResultTypes.INFO]: `validation-${ValidationResultTypes.INFO}`,
  [ValidationResultTypes.SUCCESS]: `validation-${ValidationResultTypes.SUCCESS}`,
  [ValidationResultTypes.NONE]: `validation-${ValidationResultTypes.NONE}`
};

export enum TextInputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
  DATE = 'date'
}

export enum FormFieldPlacements {
  CUSTOM = 'custom',
  FULL = 'full',
  LEFT_HALF = 'left',
  RIGHT_HALF = 'right'
}

export const formPlacementMap: Record<FormFieldPlacements, string> = {
  [FormFieldPlacements.CUSTOM]: '',
  [FormFieldPlacements.FULL]: 'basis-full',
  [FormFieldPlacements.LEFT_HALF]: 'basis-6/12 pr-2',
  [FormFieldPlacements.RIGHT_HALF]: 'basis-6/12 pl-2'
};

export enum ValidatorActivity {
  DEMAND = 'demand',
  CHANGE = 'always'
}

export enum CheckboxSelectionMode {
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export enum TimeRangeKeys {
  FROM_TIME = 'from_time',
  TO_TIME = 'to_time'
}

export const CheckboxSelectionOptions = Object.values(CheckboxSelectionMode);

export enum ApiValidationErrors {
  NAME_IS_ALREADY_USED = 'name is already used',
  DEVICE_TOKEN_NAME_IS_TAKEN = 'device token name is already taken'
}
