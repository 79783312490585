import { MediaTypes } from '~/api/schema/mediaConstants';

export enum MediaActionTypes {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}
// TODO: find out what from below can be null
interface MediaBase {
  id: number | null;
  position: number;
  mime_type: string | null;
  file_location: string | null;
  file_name: string | null;
  original_file_name: string | null;
  size: number | null;
  url: string;
}

export interface MediaAttributes {
  id?: string | number;
  file: File | string;
  medium_type: MediaTypes;
  alternative_description?: string;
}
export interface MediaColour extends MediaBase {
  medium_type: MediaTypes.COLOUR;
}

export interface MediaVideo extends MediaBase {
  medium_type: MediaTypes.VIDEO;
}

export interface MediaTitle extends MediaBase {
  medium_type: MediaTypes.TITLE;
}

export interface MediaImage extends MediaBase {
  medium_type: MediaTypes.IMAGE;
}

export interface MediaThumbnail extends MediaBase {
  medium_type: MediaTypes.THUMBNAIL;
}
export interface MediaFrameTrace extends MediaBase {
  medium_type: MediaTypes.FRAME_TRACE;
}

export type MediaColourList = Array<MediaColour>;
export type MediaVideoList = Array<MediaVideo>;
export type MediaTitleList = Array<MediaTitle>;
export type MediaImageList = Array<MediaImage>;
export type MediaThumbnailList = Array<MediaThumbnail>;

type Media = MediaColour | MediaVideo | MediaTitle | MediaImage | MediaThumbnail | MediaFrameTrace;

export type MediaList = Array<Media>;

export default Media;
