//~~legacy~~
import Vue from 'vue';
import Router from 'vue-router';

import { resetModals } from '~/components/utility/modalUtility';

Vue.use(Router);
/* Layout */
import Layout from '@/layout/index.vue';
import { setupCheckVersionTimer, checkVersion, isPROD, PlatformType } from '~/utils/application';
import { PermissionType, PermissionValue } from '~/api/schema/accountConstants';
import { AnalyticsTypes, AnalyticsTypesTitlesMap } from '~/api/schema/analytics';
import { LensOrderStatuses, LensOrderStatusesTitlesMap, LensOrderStatusesPathsMap } from '~/api/schema/lensOrders';
import { FulfillmentTypes } from '~/api/schema/fulfillmentConstants';
import { isFlagOn } from '~/utils/featureFlags';
import useStore from '@/store';

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    name: 'redirect',
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue')
      }
    ]
  },
  { path: '/web-purchases/:id(\\d+)', redirect: '/home-deliveries/:id(\\d+)', hidden: true },
  { path: '/store-purchases/:id(\\d+)', redirect: '/store-collections/:id(\\d+)', hidden: true },
  { path: '/fullfilments/web-purchases', redirect: '/fullfilments/home-deliveries', hidden: true },
  { path: '/fullfilments/store-purchases', redirect: '/fullfilments/store-collections', hidden: true },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    hidden: true
  },

  {
    path: '/dev',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'test',
        component: () => import('@/views/dev/test.vue'),
        name: 'dev-test',
        hidden: true,
        meta: { title: 'Dev test' }
      },
      {
        path: 'buttons',
        component: () => import('@/views/dev/buttons.vue'),
        name: 'dev-test',
        hidden: true,
        meta: { title: 'Dev test' }
      },
      {
        path: 'addressSearch',
        component: () => import('@/views/dev/addressSearch.vue'),
        name: 'addressSearch-test',
        hidden: true,
        meta: { title: 'Address Search test' }
      },
      {
        path: 'multidropdown',
        component: () => import('@/views/dev/multidropdown.vue'),
        name: 'multidropdown-test',
        hidden: true,
        meta: { title: 'Multi-dropdown test' }
      },
      {
        path: 'new-hto',
        component: () => import('@/views/dev/new-hto.vue'),
        name: 'dev-new-hto',
        hidden: true,
        meta: { title: 'New home trial', fulfilmentTypes: [FulfillmentTypes.HOME_TRIAL] }
      },
      {
        path: 'flags',
        component: () => import('@/views/dev/flags.vue'),
        name: 'dev-flags',
        hidden: true,
        meta: { title: 'Dev flags' }
      }
    ]
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/forgot-password/index.vue'),
    name: 'Reset Password',
    meta: {
      title: 'Reset Password'
    },
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/orders'
    // children: [
    //   {
    //     path: "dashboard",
    //     component: () => import("@/views/dashboard/index"),
    //     name: "Dashboard",
    //     meta: { title: "Dashboard", icon: "dashboard", affix: true },
    //   },
    // ],
  }
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export const asyncRoutes = [
  /** when your routing map is too long, you can split it into small modules **/
  {
    path: '/orders',
    component: Layout,
    children: [
      {
        path: '/orders',
        component: () => import('@/views/orders/index.vue'),
        name: 'orders',
        meta: { title: 'Orders', icon: 'orders' }
      },
      {
        path: '/orders/:id(\\d+)',
        component: () => import('@/views/orders/Edit/index.vue'),
        name: 'orders-id',
        meta: {
          title: 'View Order',
          noCache: true,
          activeMenu: '/orders'
        },
        hidden: true
      },
      {
        path: '/orders/:id(\\d+)/edit/hto',
        component: () => import('@/views/orders/Edit/hto.vue'),
        name: 'order-edit-hto',
        meta: {
          title: 'Edit Order',
          noCache: true,
          roles: { [PermissionType.ORDER]: [PermissionValue.EDIT] },
          activeMenu: '/orders'
        },
        hidden: true
      },
      {
        path: '/orders/:id(\\d+)/edit/purchases',
        component: () => import('@/views/orders/Edit/purchases.vue'),
        name: 'order-edit',
        meta: {
          title: 'Edit Order',
          noCache: true,
          roles: { [PermissionType.ORDER]: [PermissionValue.EDIT] },
          activeMenu: '/orders'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/fullfilments',
    component: Layout,
    redirect: '/fullfimentCenter/glasses',
    name: 'Fulfillment',
    meta: {
      title: 'Fulfilments',
      icon: 'fulfillments',
      childrenAsSubmenu: true
    },

    children: [
      {
        path: 'fulfilment-search',
        component: () => import('@/views/Fullfilments/FulfilmentSearch/index.vue'),
        name: 'Fulfilment search',
        meta: { title: 'Fulfilment search' }
      },
      {
        path: 'home-deliveries',
        component: () => import('@/views/Fullfilments/WebPurchases/index.vue'),
        name: 'Home deliveries',
        meta: { title: 'Home deliveries', fulfilmentTypes: [FulfillmentTypes.WEB_PURCHASES] }
      },
      {
        path: '/home-deliveries/:id(\\d+)',
        component: () => import('@/views/Fullfilments/WebPurchases/Edit/index.vue'),
        name: 'Home deliveries-id',
        meta: {
          title: 'Edit Home Deliveries',
          noCache: true,
          activeMenu: '/fullfilments/home-deliveries'
        },
        hidden: true
      },
      {
        path: 'store-collections',
        component: () => import('@/views/Fullfilments/StorePurchases/index.vue'),
        name: 'Store Collections',
        meta: { title: 'Store collections', fulfilmentTypes: [FulfillmentTypes.STORE_PURCHASES] }
      },
      {
        path: '/store-collections/:id(\\d+)',
        component: () => import('@/views/Fullfilments/StorePurchases/Edit/index.vue'),
        name: 'Store collections-id',
        meta: {
          title: 'Edit Store Collections',
          noCache: true,
          activeMenu: '/fullfilments/store-collections'
        },
        hidden: true
      },
      {
        path: 'accessories',
        component: () => import('@/views/fulfillments/accessories/index.vue'),
        name: 'Fulfillment Accessories',
        meta: {
          title: 'Accessories',
          fulfilmentTypes: [FulfillmentTypes.ACCESSORY],
          activeMenu: '/fullfilments'
        }
      },
      {
        path: ':order_id(\\d+)/:fulfillment_id(\\d+)',
        component: () => import('@/views/fulfillments/_order_id/_fulfillment_id.vue'),
        name: 'Individual fulfillment view',
        meta: {
          title: 'Individual fulfillment view',
          noCache: true,
          activeMenu: '/fullfilments'
        },
        hidden: true
      },
      {
        path: 'home-trial-orders',
        component: isFlagOn('NewHTOFulfillments')
          ? () => import('@/views/fulfillments/home-trial-orders/index.vue')
          : () => import('@/views/Fullfilments/HomeTrialOrders/index.vue'),
        name: 'Home Trial Order',
        meta: {
          title: 'Home trial orders',
          fulfilmentTypes: [FulfillmentTypes.HOME_TRIAL],
          activeMenu: '/fullfilments'
        }
      },
      // TODO: remove below entry when moving to a common page
      ...(isFlagOn('NewHTOFulfillments')
        ? [
            {
              path: 'home-trial-orders/:order_id(\\d+)/:fulfillment_id(\\d+)',
              component: () => import('@/views/fulfillments/_order_id/_fulfillment_id.vue'),
              name: 'Fulfillment view',
              meta: {
                title: 'Fulfillment view',
                noCache: true,
                activeMenu: '/fullfilments/home-trial-orders'
              },
              hidden: true
            }
          ]
        : []),
      {
        path: 'reglazing',
        component: () => import('@/views/Fullfilments/Reglazing/index.vue'),
        name: 'Special Services',
        meta: {
          title: 'Special services',
          fulfilmentTypes: [
            FulfillmentTypes.REGLAZING,
            FulfillmentTypes.EDITING,
            FulfillmentTypes.WARRANTY_EXCHANGE_REQUEST
          ]
        }
      },
      {
        path: '/reglazing/:id(\\d+)',
        component: () => import('@/views/Fullfilments/Reglazing/Edit/index.vue'),
        name: 'Special Services-id',
        meta: {
          title: 'Edit Special Services',
          noCache: true,
          activeMenu: '/fullfilments/reglazing'
        },
        hidden: true
      },
      {
        path: '/edits/:id(\\d+)',
        component: () => import('@/views/Fullfilments/Edits/index.vue'),
        name: 'Edits',
        meta: {
          title: 'Edits',
          noCache: true,
          activeMenu: '/fullfilments/reglazing'
        },
        hidden: true
      },
      {
        path: '/warranty/:id(\\d+)',
        component: () => import('@/views/Fullfilments/Warranty/index.vue'),
        name: 'Warranty exchange request',
        meta: {
          title: 'Warranty exchange request',
          noCache: true,
          activeMenu: '/fullfilments/reglazing'
        },
        hidden: true
      },
      // TODO: remove below entry when releasing and removing NewHTOFulfillments flag
      {
        path: '/home-trial-orders/:id(\\d+)',
        component: () => import('@/views/Fullfilments/HomeTrialOrders/Edit/index.vue'),
        name: 'Home Trial Order-id',
        meta: {
          title: 'Home Trial Order',
          noCache: true,
          activeMenu: '/fullfilments/home-trial-orders'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/customers',
    component: Layout,
    children: [
      {
        path: '/customers',
        component: () => import('@/views/customers/index.vue'),
        name: 'Customers',
        meta: { title: 'Customers', icon: 'customers' }
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/customers/Edit/index.vue'),
        name: 'customer-edit',
        meta: {
          title: 'Edit Customer',
          noCache: true,
          activeMenu: '/customers'
        },
        hidden: true
      },
      {
        path: 'EyeTest/:id(\\d+)',
        component: () => import('@/views/customers/eyeTest/index.vue'),
        hidden: true
      },
      {
        path: '/EyeTest/add/:id(\\d+)',
        component: () => import('@/views/customers/eyeTest/Add/index.vue'),
        name: 'Add EyeTest',
        meta: {
          title: 'Add EyeTest',
          noCache: true,
          activeMenu: '/EyeTest'
        },
        hidden: true
      },
      {
        path: '/EyeTest/edit/:id(\\d+)',
        component: () => import('@/views/customers/eyeTest/Edit/index.vue'),
        name: 'Edit EyeTest',
        meta: {
          title: 'Edit EyeTest',
          noCache: true,
          activeMenu: '/EyeTest'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/lens-orders',
    component: Layout,
    redirect: '/lens-orders/new',
    name: 'Lens Orders',
    meta: {
      title: 'Lens Orders',
      icon: 'lens',
      childrenAsSubmenu: true
    },
    children: Object.values(LensOrderStatuses).map((type) => ({
      path: LensOrderStatusesPathsMap[type],
      component: () => import('@/views/lensOrders/_type.vue'),
      name: `${type}-lens-orders`,
      meta: {
        title: LensOrderStatusesTitlesMap[type],
        noCache: true,
        activeMenu: '/lens-orders',
        type
      }
    }))
  },
  {
    path: '/store-inventory',
    component: Layout,
    redirect: '/store-inventory/targets',
    name: 'StoreInventory',
    meta: {
      title: 'Inventory',
      icon: 'storeInventory',
      childrenAsSubmenu: true,
      roles: [
        PermissionType.STORE_INVENTORY_REQUESTS,
        PermissionType.STORE_INVENTORY_RETURNS,
        PermissionType.STORE_INVENTORY_TARGETS
      ]
    },
    children: [
      {
        path: 'targets',
        component: () => import('@/views/storeInventory/targets.vue'),
        name: 'Targets',
        meta: {
          title: 'Targets',
          noCache: true,
          activeMenu: '/store-inventory',
          roles: [PermissionType.STORE_INVENTORY_TARGETS]
        }
      },
      {
        path: 'requests',
        component: () => import('@/views/storeInventory/requests.vue'),
        name: 'Requests Orders',
        meta: {
          title: 'Requests',
          noCache: true,
          activeMenu: '/store-inventory',
          roles: [PermissionType.STORE_INVENTORY_REQUESTS]
        }
      },
      {
        path: 'returns',
        component: () => import('@/views/storeInventory/returns.vue'),
        name: 'Returns',
        meta: {
          title: 'Returns',
          noCache: true,
          activeMenu: '/store-inventory',
          roles: [PermissionType.STORE_INVENTORY_RETURNS]
        }
      }
    ]
  },
  {
    path: '/shipments',
    component: Layout,
    redirect: '/Shipments/glasses',
    name: 'Shipments',
    meta: {
      title: 'Shipments',
      icon: 'shipments',
      childrenAsSubmenu: true
    },

    children: [
      ...(isFlagOn('NewHTOFulfillments')
        ? []
        : [
            {
              path: 'HTOTrialRadar',
              component: () => import('@/views/shipments/HTOTrialRadar/index.vue'),
              name: 'HTOTrialRadar',
              meta: { title: 'Home trial radar' }
            }
          ]),
      {
        path: 'ShipmetRadarDtc',
        component: () => import('@/views/shipments/ShipmetRadarDtc/index.vue'),
        name: 'ShipmetRadarDtc',
        meta: { title: 'Website deliveries' }
      },
      {
        path: 'ShipmentRadarDtpull',
        component: () => import('@/views/shipments/ShipmentRadarDtpull/index.vue'),
        name: 'ShipmentRadarDtpull',
        meta: { title: 'Store deliveries' }
      }
    ]
  },
  {
    path: '/products',
    component: Layout,
    redirect: '/products/frames',
    name: 'Products',
    meta: {
      title: 'Products',
      icon: 'products',
      childrenAsSubmenu: true,
      roles: [
        PermissionType.COLLECTIONS,
        PermissionType.FRAMES,
        PermissionType.LENSES,
        PermissionType.GLASSES,
        PermissionType.INVENTORY
      ]
    },
    children: [
      {
        path: '/collections',
        component: () => import('@/views/collections/index.vue'),
        name: 'Collections',
        meta: { title: 'Collections', roles: [PermissionType.COLLECTIONS] }
      },
      {
        path: '/collections/:id(\\d+)',
        component: () => import('@/views/collections/Edit/index.vue'),
        name: 'Collections-id',
        meta: {
          title: 'Edit Collections',
          noCache: true,
          activeMenu: '/collections',
          roles: [PermissionType.COLLECTIONS]
        },
        hidden: true
      },
      {
        path: '/collections/create',
        component: () => import('@/views/collections/Create/index.vue'),
        name: 'Collections-add',
        meta: {
          title: 'Create Collections',
          noCache: true,
          activeMenu: '/collections',
          roles: { [PermissionType.COLLECTIONS]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'tailorMadeGlasses',
        component: () => import('@/views/products/tailorMadeGlasses/index.vue'),
        name: 'Tailor Made Glasses',
        meta: { title: 'Tailor made glasses' },
        hidden: true
      },
      {
        path: 'tailorMadeGlasses/create',
        component: () => import('@/views/products/tailorMadeGlasses/Create/index.vue'),
        name: 'Tailor Made Glasses-add',
        meta: {
          title: 'Create Tailor',
          noCache: true,
          activeMenu: '/products/tailorMadeGlasses'
        },
        hidden: true
      },
      {
        path: 'tailorMadeGlasses/:id(\\d+)',
        component: () => import('@/views/products/tailorMadeGlasses/Edit/index.vue'),
        name: 'Tailor Made Glasses-id',
        meta: {
          title: 'Edit Tailor',
          noCache: true,
          activeMenu: '/products/tailorMadeGlasses'
        },
        hidden: true
      },
      {
        path: 'frames',
        component: () => import('@/views/products/frames/index.vue'),
        name: 'Frames',
        meta: { title: 'Frames', roles: [PermissionType.FRAMES] }
      },
      {
        path: 'frames/:id(\\d+)',
        component: () => import('@/views/products/frames/Edit/index.vue'),
        name: 'EditFrame',
        meta: {
          title: 'Edit Frame',
          noCache: true,
          activeMenu: '/products/frames',
          roles: [PermissionType.FRAMES]
        },
        hidden: true
      },
      {
        path: 'frames/frame-variant/:id(\\d+)',
        component: () => import('@/views/products/frames/Edit/EditVariant/index.vue'),
        name: 'EditVariant',
        meta: {
          title: 'Edit Variant',
          noCache: true,
          activeMenu: '/products/frames',
          roles: [PermissionType.FRAMES]
        },
        hidden: true
      },
      {
        path: 'frames/create',
        component: () => import('@/views/products/frames/Create/index.vue'),
        name: 'CreateFrame',
        meta: {
          title: 'Create Frame',
          noCache: true,
          activeMenu: '/products/frames',
          roles: { [PermissionType.FRAMES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'frames/createvariant',
        component: () => import('@/views/products/frames/Create/CreateVariant/index.vue'),
        name: 'CreateVariant',
        meta: {
          title: 'Create Frame Variant',
          noCache: true,
          activeMenu: '/products/frames',
          roles: { [PermissionType.FRAMES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'accessories',
        component: () => import('@/views/products/accessories/index.vue'),
        name: 'accessories',
        meta: {
          title: 'Accessories',
          roles: [PermissionType.ACCESSORIES]
        }
      },
      {
        path: 'accessories/edit/:id(\\d+)',
        component: () => import('@/views/products/accessories/_id.vue'),
        name: 'accessories-edit',
        meta: {
          title: 'Edit accessory',
          noCache: true,
          activeMenu: '/products/accessories',
          roles: { [PermissionType.ACCESSORIES]: [PermissionValue.EDIT, PermissionValue.VIEW] }
        },
        hidden: true
      },
      {
        path: 'accessories/add',
        component: () => import('@/views/products/accessories/_id.vue'),
        name: 'accessories-add',
        meta: {
          title: 'Create accessory',
          noCache: true,
          activeMenu: '/products/accessories',
          roles: { [PermissionType.ACCESSORIES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'lenses',
        component: () => import('@/views/products/lenses/index.vue'),
        name: 'Lenses',
        meta: { title: 'Lenses', roles: [PermissionType.LENSES] }
      },
      {
        path: 'lenses/lens-variant/:id(\\d+)',
        component: () => import('@/views/products/lenses/Edit/EditVariant/index.vue'),
        name: 'EditLensVariant',
        meta: {
          title: 'Edit Lens Variant',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: [PermissionType.LENSES]
        },
        hidden: true
      },
      {
        path: 'lenses/:id(\\d+)',
        component: () => import('@/views/products/lenses/Edit/index.vue'),
        name: 'EditLens',
        meta: {
          title: 'Edit Lens',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: [PermissionType.LENSES]
        },
        hidden: true
      },
      {
        path: 'lenses/edit',
        component: () => import('@/views/products/lenses/Edit/index.vue'),
        name: 'Edit lens',
        meta: {
          title: 'Edit Lens',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: [PermissionType.LENSES]
        },
        hidden: true
      },
      {
        path: 'lenses/createvariant',
        component: () => import('@/views/products/lenses/Create/CreateVariant/index.vue'),
        name: 'CreateLensVariant',
        meta: {
          title: 'Create Lens Variant',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: { [PermissionType.LENSES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'lenses/create',
        component: () => import('@/views/products/lenses/Create/index.vue'),
        name: 'Create Lense',
        meta: {
          title: 'Create Lens',
          noCache: true,
          activeMenu: '/products/lenses',
          roles: { [PermissionType.LENSES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'glasses',
        component: () => import('@/views/products/glasses/index.vue'),
        name: 'Glasses',
        meta: { title: 'Glasses', roles: [PermissionType.GLASSES] }
      },
      {
        path: 'glasses/create',
        component: () => import('@/views/products/glasses/Create/index.vue'),
        name: 'CreateGlasses',
        meta: {
          title: 'Create Glasses',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: { [PermissionType.GLASSES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'glasses/createvariant',
        component: () => import('@/views/products/glasses/Create/CreateVariant/index.vue'),
        name: 'createglassvariant',
        meta: {
          title: 'Create Glass Variant',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: { [PermissionType.GLASSES]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: 'glasses/glass-variant/:id(\\d+)',
        component: () => import('@/views/products/glasses/Edit/EditVariant/index.vue'),
        name: 'editglassvariant',
        meta: {
          title: 'Edit Glass Variant',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: [PermissionType.GLASSES]
        },
        hidden: true
      },
      {
        path: 'glasses/glass-variant/hto/:id(\\d+)',
        component: () => import('@/views/products/glasses/Edit/EditVariant/HTO/index.vue'),
        name: 'editglassvarianthto',
        meta: {
          title: 'Glass Variant HTO',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: [PermissionType.GLASSES]
        },
        hidden: true
      },
      {
        path: 'glasses/:id(\\d+)',
        component: () => import('@/views/products/glasses/Edit/index.vue'),
        name: 'Editglasses',
        meta: {
          title: 'Edit Glasses',
          noCache: true,
          activeMenu: '/products/glasses',
          roles: [PermissionType.GLASSES]
        },
        hidden: true
      },

      {
        path: '/inventory',
        component: () => import('@/views/inventory/index.vue'),
        name: 'Inventory',
        meta: { title: 'Inventory', roles: [PermissionType.INVENTORY] }
      }
    ]
  },
  {
    path: '/gift-cards',
    component: Layout,
    meta: { icon: 'giftCards', roles: { [PermissionType.GIFT_CARDS]: [PermissionValue.EDIT] } },
    children: [
      {
        path: '/gift-cards',
        component: () => import('@/views/gift-cards/index.vue'),
        name: 'gift-cards',
        meta: { title: 'E-gift cards' },
        roles: { [PermissionType.GIFT_CARDS]: [PermissionValue.EDIT] }
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/gift-cards/_id.vue'),
        name: 'gift-cards-id',
        meta: {
          title: 'Edit E-gift card',
          noCache: true,
          activeMenu: '/gift-cards',
          roles: { [PermissionType.GIFT_CARDS]: [PermissionValue.EDIT] }
        },
        hidden: true
      }
    ]
  },
  {
    path: '/discounts',
    component: Layout,
    meta: { title: 'Discounts', icon: 'discounts', roles: [PermissionType.DISCOUNTS] },
    children: [
      {
        path: '/discounts',
        component: () => import('@/views/discounts/index.vue'),
        name: 'Discounts',
        meta: { title: 'Discounts', roles: [PermissionType.DISCOUNTS] }
      },
      {
        path: 'create',
        component: () => import('@/views/discounts/Create/index.vue'),
        name: 'CreateDiscount',
        meta: {
          title: 'Create Discount',
          noCache: true,
          activeMenu: '/discounts',
          roles: { [PermissionType.DISCOUNTS]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/discounts/Edit/index.vue'),
        name: 'EditDiscount',
        meta: {
          title: 'Edit Discount',
          noCache: true,
          activeMenu: '/discounts',
          roles: [PermissionType.DISCOUNTS]
        },
        hidden: true
      }
    ]
  },
  {
    path: '/blog',
    component: Layout,
    meta: {
      title: 'Blog',
      icon: 'blogs',
      roles: [PermissionType.BLOG]
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/blog/index.vue'),
        name: 'blog',
        meta: { title: 'Blog', icon: 'blogs', roles: [PermissionType.BLOG] },
        activeMenu: '/blog'
      },
      {
        path: 'Add',
        component: () => import('@/views/blog/Add/index.vue'),
        name: 'Addblog',
        meta: {
          title: 'Add Blog',
          noCache: true,
          activeMenu: '/blog',
          roles: { [PermissionType.BLOG]: [PermissionValue.EDIT] }
        },
        hidden: true
      },
      {
        path: ':id(\\d+)',
        component: () => import('@/views/blog/Edit/index.vue'),
        name: 'Editblog',
        meta: {
          title: 'Edit Blog',
          noCache: true,
          activeMenu: '/blog',
          roles: [PermissionType.BLOG]
        },
        hidden: true
      }
    ]
  },

  {
    path: '/analytics',
    component: Layout,
    redirect: '/analytics/revenue',
    name: 'Analytics',
    meta: {
      title: 'Analytics',
      icon: {
        name: 'analytics',
        useFill: true
      },
      childrenAsSubmenu: true,
      roles: [PermissionType.ANALYTICS],
      noScrollbar: true
    },
    children: Object.values(AnalyticsTypes).map((type) => ({
      path: type,
      component: () => import('@/views/analytics/_type.vue'),
      name: `${type}-analytics`,
      meta: {
        title: AnalyticsTypesTitlesMap[type],
        noCache: true,
        activeMenu: '/analytics',
        type,
        noScrollbar: true
      }
    }))
  },
  {
    path: '/pd-tool',
    component: Layout,
    activeMenu: '/pd-tool',
    children: [
      {
        path: '/pd-tool',
        component: () => import('@/views/pd-tool.vue'),
        name: 'pd-tool',
        meta: { title: 'PD tool', icon: 'pdtool' },
        activeMenu: '/pd-tool'
      }
    ]
  },
  {
    path: '/settings',
    component: Layout,
    name: 'settings',
    meta: {
      title: 'Settings',
      icon: 'settings',
      isBottom: true,
      roles: [PermissionType.LOCATIONS, PermissionType.SALES_CHANNELS, PermissionType.ADMINS]
    },
    activeMenu: '/settings',
    children: [
      {
        path: 'accounts',
        component: () => import('@/views/settings/accounts/index.vue'),
        name: 'accounts',
        meta: {
          title: 'Admin users',
          noCache: true,
          activeMenu: '/settings',
          roles: [PermissionType.ADMINS],
          menu: true,
          showOnlyOnActiveMenu: true
        },
        hidden: true
      },
      {
        path: 'accounts/:id(\\d+|create)',
        component: () => import('@/views/settings/accounts/_id.vue'),
        name: 'accounts-id',
        meta: {
          title: 'Edit Account',
          noCache: true,
          activeMenu: '/settings',
          roles: [PermissionType.ADMINS]
        },
        hidden: true
      },

      {
        path: 'sales-channels',
        name: 'sales-channels',
        component: () => import('@/views/settings/sales-channels/index.vue'),
        meta: {
          title: 'Sales channels ',
          activeMenu: '/settings',
          childrenAsSubmenu: true,
          menu: true,
          showOnlyOnActiveMenu: true,
          roles: [PermissionType.SALES_CHANNELS]
        },
        hidden: true,

        children: [
          {
            path: '/settings/sales-channels/website',
            component: () => import('@/views/settings/sales-channels/website.vue'),
            name: 'sales-channels-website',
            meta: {
              title: 'Website',
              noCache: true,
              activeMenu: '/settings',
              menu: true,
              roles: [PermissionType.SALES_CHANNELS]
            }
          },
          {
            path: '/settings/sales-channels/stores',
            component: () => import('@/views/settings/sales-channels/stores/index.vue'),
            name: 'sales-channels-stores',
            meta: {
              title: 'Stores',
              noCache: true,
              activeMenu: '/settings',
              menu: true,
              roles: [PermissionType.SALES_CHANNELS]
            }
          },
          {
            path: 'stores/:id(\\d+|create)',
            component: () => import('@/views/settings/sales-channels/stores/_id.vue'),
            name: 'sales-channels-stores-id',
            meta: {
              title: 'Edit store',
              noCache: true,
              activeMenu: '/settings',
              roles: [PermissionType.SALES_CHANNELS]
            },
            hidden: true
          }
        ]
      },
      {
        path: 'locations',
        component: () => import('@/views/settings/locations/index.vue'),
        name: 'locations',
        meta: {
          title: 'Fulfilment centres',
          noCache: true,
          activeMenu: '/settings',
          roles: [PermissionType.LOCATIONS],
          menu: true,
          showOnlyOnActiveMenu: true
        },
        hidden: true
      },
      {
        path: 'locations/:id(\\d+|create)',
        component: () => import('@/views/settings/locations/_id.vue'),
        name: 'locations-id',
        meta: {
          title: 'Edit Location',
          noCache: true,
          activeMenu: '/settings',
          roles: [PermissionType.LOCATIONS]
        },
        hidden: true
      },

      {
        path: 'devices',
        name: 'devices',
        component: () => import('@/views/settings/devices/index.vue'),
        meta: {
          title: 'Authorised devices',
          activeMenu: '/settings',
          childrenAsSubmenu: true,
          menu: true,
          showOnlyOnActiveMenu: true,
          roles: [PermissionType.DEVICE_TOKENS]
        },
        hidden: true,

        children: [
          {
            path: '/settings/devices/hub',
            component: () => import('@/views/settings/devices/_type.vue'),
            name: 'devices-hub',
            meta: {
              title: 'Authorised devices',
              updatedTitle: 'HUB',
              noCache: true,
              activeMenu: '/settings',
              menu: true,
              roles: [PermissionType.DEVICE_TOKENS],
              type: PlatformType.HUB
            }
          },
          {
            path: '/settings/devices/pos',
            component: () => import('@/views/settings/devices/_type.vue'),
            name: 'devices-pos',
            meta: {
              title: 'Authorised devices',
              updatedTitle: 'POS',
              noCache: true,
              activeMenu: '/settings',
              menu: true,
              roles: [PermissionType.DEVICE_TOKENS],
              type: PlatformType.POS
            }
          },
          {
            path: '/settings/devices/med',
            component: () => import('@/views/settings/devices/_type.vue'),
            name: 'devices-med',
            meta: {
              title: 'Authorised devices',
              updatedTitle: 'MED',
              noCache: true,
              activeMenu: '/settings',
              menu: true,
              roles: [PermissionType.DEVICE_TOKENS],
              type: PlatformType.MED
            }
          }
        ]
      }
    ]
  },
  // 404 page must be placed at the end !!!
  { path: '/:pathMatch(.*)*', redirect: '/404', hidden: true }
];

const routesToPreventScrollToTop = ['lens-orders'];
const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: (to, from) => {
      if (to.path === from.path && routesToPreventScrollToTop.some((path) => to.path.includes(path))) {
        return false;
      } else {
        return { y: 0 };
      }
    },
    routes: constantRoutes
  });

const router = createRouter();

const pathRegexps = constantRoutes
  .map((route) => route.path)
  .concat(asyncRoutes.map((route) => route.path))
  .filter((route) => route?.length > 1)
  .map((path) => new RegExp(`^#${path}?.+$`));

router.beforeEach((to, from, next) => {
  if (isPROD() && to.fullPath.includes('dev/')) {
    next('/');
  } else {
    if (to.path !== from.path) {
      // reset modals if any open
      resetModals();
      // reset current fulfilment type
      const store = useStore();
      store.commit('fulfillments/setCurrentFulfillmentType', undefined);
    }

    //redirect fix for old links with /#/
    if (to.hash.includes('#/') && pathRegexps.some((regexp) => regexp.test(to.hash))) {
      next(to.hash.replace('#', ''));
    } else {
      next();
    }
  }
});

router.afterEach((to, _from) => {
  // check application version
  checkVersion();
  // setup version check timer
  setupCheckVersionTimer();
  // show or hide scroll bars properly to the page meta tags
  document.documentElement.style.setProperty('--vertical-scroll', to.meta?.noScrollbar ? 'auto' : 'scroll');
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
