import { createStore, entries, setMany, clear } from 'idb-keyval';
import { computed, ref } from 'vue';
import { getApplicationType } from '~/utils/application';

export type Store = Record<string, Record<string, any>>;
type IdbStoreItem = [string, Record<string, any>];

const hydrated = ref(false);
export const isHydrated = computed(() => hydrated.value);

const storeIdbInstance = ref();
let idbTransactionInProgress = false;
const idbTransactionQueue: Array<{ data: Array<IdbStoreItem>; at: number }> = [];

function getStoreIdbInstance() {
  if (!storeIdbInstance.value) {
    const NAME = `persisted-${getApplicationType().toLowerCase()}-data`;
    storeIdbInstance.value = createStore(NAME, `${NAME}-store`);
  }
  return storeIdbInstance.value;
}

//Use this function on app load to move data from Idb
export async function getIdbStoreData(): Promise<Store | undefined> {
  try {
    const storeData = await entries(getStoreIdbInstance());
    idbTransactionInProgress = false;
    return Object.fromEntries(storeData);
  } catch (error) {
    console.error('Error getting idb store data.', error);
  }
  return undefined;
}

//Use this function anytime application store updates to save changes
//TODO: remove force param when migration function are removed
export async function setIdbStoreData(data: Partial<Store>, keys: Array<string>, force = false) {
  //Only save data after hydration is complete, this way nothing is overwritten.
  if (isHydrated.value || force) {
    const filteredData = keys.reduce((result, name) => {
      if (data?.[name]) {
        result.push([name, JSON.parse(JSON.stringify(data[name])) as Record<string, any>]);
      }
      return result;
    }, [] as Array<IdbStoreItem>);
    if (filteredData.length) {
      //Since operations are async we need to make sure saved happen in the same order
      //Anything wanting to save while a transaction is running goes into the queue
      if (idbTransactionInProgress) {
        idbTransactionQueue.push({ data: filteredData, at: Date.now() });
      } else {
        try {
          idbTransactionInProgress = true;
          //Apply queued transactions before we apply the current one
          await parseTransactionQueue();
          setMany(filteredData, getStoreIdbInstance())
            .then(async () => {
              //Apply transactions collected while values were saved
              await parseTransactionQueue();
              idbTransactionInProgress = false;
            })
            .catch((error) => console.error('Error saving idb store data.', error));
        } catch (error) {
          console.error('Error saving idb store data (awaited).', error);
        }
      }
    }
  }
}

async function parseTransactionQueue() {
  const idbInstance = getStoreIdbInstance();
  while (idbTransactionQueue.length) {
    const queuedData = idbTransactionQueue.shift();
    if (!!queuedData) {
      await setMany(queuedData.data, idbInstance);
    }
  }
}

export function setIdbStoreHydrated(value: boolean) {
  hydrated.value = value;
}

export function clearIdbStore() {
  idbTransactionInProgress = true;
  clear(getStoreIdbInstance())
    .then(() => {
      idbTransactionQueue.length = 0;
      idbTransactionInProgress = false;
    })
    .catch((error) => console.error('Error clearing idb store data.', error));
}

export async function ensureStoreLoaded(timeout = 5000) {
  const CHECK_INTERVAL = 100;
  return new Promise((resolve) => {
    if (isHydrated.value) {
      resolve(undefined);
    }
    const started = Date.now();
    const interval = setInterval(() => {
      if (isHydrated.value || Date.now() - started > timeout) {
        clearInterval(interval);
        resolve(undefined);
      }
    }, CHECK_INTERVAL);
  });
}
