import { Administrator } from './account';
import { ISODateTime, RequestQueryFilters } from './common';
import { Measurement } from './eyeTest';
import { EyePositions } from './eyeTestConstants';
import { LensTransitionColours } from './orderItemConstants';
import { MediaActionTypes } from './media';
import type { MediaList } from './media';

export enum LensOrdersMediaErrorMSGs {
  WRONG_TYPE = "It wasn't possible to upload this file because the file type must be OMA.",
  WRONG_FILENAME = "It wasn't possible to upload this file because the file name must match the lens order reference.",
  WRONG_TYPE_AND_FILENAME = "It wasn't possible to upload this file because the file type must be OMA and the file name must match the lens order reference."
}

export const LensMediaActionsInfoMap = {
  [MediaActionTypes.CREATE]: {
    title: 'File added to the other eye',
    message: 'This trace file was added to the other eye as well.'
  },
  [MediaActionTypes.UPDATE]: {
    title: 'File updated for the other eye',
    message: 'This trace file was updated for the other eye as well.'
  },
  [MediaActionTypes.DELETE]: {
    title: 'File deleted for the other eye',
    message: 'This trace file was deleted for both orders that share the same frame.'
  }
};

export enum LensOrderStatuses {
  NEW = 'new',
  TO_TRACE = 'to_trace',
  ONGOING = 'ongoing',
  RECEIVED = 'received',
  CANCELED = 'canceled'
}

export const LensOrderStatusesTitlesMap = {
  [LensOrderStatuses.NEW]: 'New',
  [LensOrderStatuses.TO_TRACE]: 'To Trace',
  [LensOrderStatuses.ONGOING]: 'Ongoing',
  [LensOrderStatuses.RECEIVED]: 'Received',
  [LensOrderStatuses.CANCELED]: 'Cancelled'
};

export const LensOrderStatusesPathsMap = {
  [LensOrderStatuses.NEW]: 'new',
  [LensOrderStatuses.TO_TRACE]: 'to-trace',
  [LensOrderStatuses.ONGOING]: 'ongoing',
  [LensOrderStatuses.RECEIVED]: 'received',
  [LensOrderStatuses.CANCELED]: 'cancelled'
};

export enum LensOrderSuppliers {
  ZEISS = 'zeiss',
  WANXIN = 'wanxin',
  JK = 'jk',
  WLC = 'wlc',
  CONANT = 'conant',
  OTHER = 'other'
}

export enum LensOrderGeneration {
  AUTO = 'auto',
  MANUAL = 'manual'
}

export enum LensOrderStockType {
  STOCK = 'stock',
  SURFACED = 'surfaced'
}

export enum LensOrderGlazingType {
  IN_HOUSE = 'in_house',
  REMOTE = 'remote'
}

export enum LensOrderDates {
  CREATED_AT = 'created_at',
  TO_TRACE_SINCE = 'to_trace_since',
  ONGOING_SINCE = 'ongoing_since',
  RECEIVED_AT = 'received_at',
  CANCELLED_AT = 'cancelled_at'
}

export enum LensOrderCancelTypes {
  EDIT_ORDER = 'edit_order',
  MANUAL = 'manual'
}

export const lensOrderDatesLabelMap = {
  [LensOrderDates.TO_TRACE_SINCE]: 'To trace since',
  [LensOrderDates.ONGOING_SINCE]: 'Ongoing since',
  [LensOrderDates.RECEIVED_AT]: 'Received on',
  [LensOrderDates.CANCELLED_AT]: 'Cancelled on',
  [LensOrderDates.CREATED_AT]: 'Created on'
};

export const lensOrderDateByStatusMap = {
  [LensOrderStatuses.NEW]: LensOrderDates.CREATED_AT,
  [LensOrderStatuses.TO_TRACE]: LensOrderDates.TO_TRACE_SINCE,
  [LensOrderStatuses.ONGOING]: LensOrderDates.ONGOING_SINCE,
  [LensOrderStatuses.RECEIVED]: LensOrderDates.RECEIVED_AT,
  [LensOrderStatuses.CANCELED]: LensOrderDates.CANCELLED_AT
};

export enum LensOrderDatesByAdministrator {
  CREATED_BY = 'created_by_administrator',
  TO_TRACE_BY = 'to_trace_by_administrator',
  ONGOING_BY = 'ongoing_by_administrator',
  RECEIVED_BY = 'received_by_administrator',
  CANCELLED_BY = 'cancelled_by_administrator'
}

export const lensOrderAdministratorMap: Record<LensOrderDates, LensOrderDatesByAdministrator> = {
  [LensOrderDates.CREATED_AT]: LensOrderDatesByAdministrator.CREATED_BY,
  [LensOrderDates.TO_TRACE_SINCE]: LensOrderDatesByAdministrator.TO_TRACE_BY,
  [LensOrderDates.ONGOING_SINCE]: LensOrderDatesByAdministrator.ONGOING_BY,
  [LensOrderDates.RECEIVED_AT]: LensOrderDatesByAdministrator.RECEIVED_BY,
  [LensOrderDates.CANCELLED_AT]: LensOrderDatesByAdministrator.CANCELLED_BY
};

export const suppliersLabelMap: Record<LensOrderSuppliers, string> = {
  [LensOrderSuppliers.JK]: 'JK',
  [LensOrderSuppliers.ZEISS]: 'Zeiss',
  [LensOrderSuppliers.WANXIN]: 'Wanxin',
  [LensOrderSuppliers.WLC]: 'WLC',
  [LensOrderSuppliers.CONANT]: 'Conant',
  [LensOrderSuppliers.OTHER]: 'Other'
};

export type LensOrdersStats = Record<LensOrderStatuses, { total: number }>;

export interface LensOrder
  extends Partial<
    Record<LensOrderDates, ISODateTime | null> & Record<LensOrderDatesByAdministrator, Administrator | null>
  > {
  id: number;
  eye_position: EyePositions;
  status: LensOrderStatuses;
  generation: LensOrderGeneration;
  stock_type: LensOrderStockType;
  reference: string;
  supplier?: LensOrderSuppliers | null;
  notes?: string | null;
  colour: string;
  transition_colour?: LensTransitionColours | null;
  brand: string;
  lens_index: string;
  blank_size?: number | null;
  frame_details: string;
  minimum_blank_size: number | string;
  pupillary_distance: string | number | null;
  prescription_url: string;
  overview?: string | null;
  absorption_percentage?: string | number | null;
  lens_coating?: string | null;
  glazing_type?: LensOrderGlazingType | null;
  ordered_at: ISODateTime | null;
  frame_and_lens_display: string;
  rx_display: string;
  lens_measurements_display: string;
  eye_measurement: Measurement;
  media: MediaList | null;
  cancel_type?: LensOrderCancelTypes;
}

export interface LensOrdersMeta {
  total_count: number;
}

export interface LensOrdersList {
  lens_orders: LensOrder[];
  meta: LensOrdersMeta;
}

export enum LensOrdersBulkUpdateTypes {
  GLAZING_TYPE = 'glazing_type',
  SUPPLIER = 'supplier',
  STATUS = 'status'
}

export interface LensOrderListItem extends Partial<Record<LensOrderDates, string>> {
  id: number | string;
  status: LensOrderStatuses;
  reference: string;
  eye_position: string;
  overview: string[];
  supplier?: LensOrderSuppliers | null;
  glazing_type?: LensOrderGlazingType | null;
  tracing_file: string;
  tooltipMessages?: string[];
  media: MediaList | null;
  notes?: string | null;
  isUploadLoading?: boolean;
}

export type LensOrdersQueryFilters = RequestQueryFilters<
  LensOrder & { group_by_reference_when_sorting: boolean; group_sorting_by_eye_position: EyePositions }
>;
