import { detect } from 'detect-browser';
import VueRouter, { RawLocation, Route } from 'vue-router';

export enum Routes {
  LOGIN = 'login',
  FORGOT_PASSWORD = 'forgot-password',
  INDEX = 'index',
  CHECKOUT = 'checkout'
}

export enum OutdatedRoutes {
  MASTER_LOGIN = 'master-login'
}

const guestRoutes = [Routes.LOGIN, Routes.FORGOT_PASSWORD];

export const generateFingerPrintToken = async () => {
  let fingerprint: string | undefined;
  const timestamp = new Date().getTime();
  const rand = (Math.random() * 100000000).toFixed();
  const browser = detect();
  if (window.crypto.subtle) {
    const hash = await window.crypto.subtle.digest(
      'SHA-1',
      new TextEncoder().encode(JSON.stringify([timestamp, browser, rand]))
    );
    fingerprint = Array.from(new Uint8Array(hash))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  } else {
    fingerprint = `${Math.floor(Math.random() * 10000000)}`;
  }
  return fingerprint;
};

export const isGuestRoute = (path?: string | null) => guestRoutes.some((route) => path?.includes(route));

export const isOutdatedRoute = (path?: string | null) =>
  Object.values(OutdatedRoutes).some((route) => path?.includes(route));

/**
 * Redirect to the login page with providing previous URL in the "redirect" query string parameter after cleaning it up
 * from the temporary query string params
 * @param redirect - optional middleware "redirect" function, will have priority in use
 * @param route - optional Vue route, if not specified, window.location.href will be (limitely) used
 * @param router - optional Vue router, if not specified (and no redirect param), window.location.href will be assigned to redirect
 */
export const redirectToLogin = async (redirect?: any, route?: Route, router?: VueRouter) => {
  const newRoute: RawLocation = { path: '/login' };

  if (route) {
    if (route.path === '/login') {
      // use existing redirecting query params
      newRoute.query = { redirect: route.query?.redirect };
    } else {
      // store currently requested page in params to redirect back to it after the login
      const query = route.query;
      const queryString = Object.keys(route.query)
        .map((key) => `${key}=${encodeURIComponent(query[key] as string)}`)
        .join('&');
      newRoute.query = { redirect: `${route.path}${queryString ? `?${queryString}` : ''}` };
    }
  } else {
    // store currently requested page in params to redirect back to it after the login
    // we clean up certain query parameters that are not needed to remember, so we need to
    // clearly split up the URL and process it
    const url = new URL(window.location.href);

    const query = {};
    for (const [key, value] of url.searchParams.entries()) {
      query[key] = value;
    }
    const queryString = Object.keys(query)
      .map((key) => `${key}=${encodeURIComponent(query[key] as string)}`)
      .join('&');
    newRoute.query = { redirect: `${url.pathname}${queryString ? `?${queryString}` : ''}` };
  }

  if (redirect) {
    await redirect(newRoute);
  } else if (router) {
    await router.replace(newRoute);
  } else {
    window.location.href = `/login?redirect=${encodeURIComponent(newRoute.query.redirect as string)}`;
  }
};
