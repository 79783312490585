import { ref } from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';
import { StatusCodes } from 'http-status-codes';
import { isAdminApplication, isPROD } from '~/utils/application';
import { FulfillmentTypes } from '~/api/schema/fulfillmentConstants';

interface TFlag {
  id: string;
  name: string;
  description?: string;
  link?: string;
}

const flags: TFlag[] = [
  {
    id: 'NewHTOFulfillments',
    name: 'New HTO Fulfillment pages',
    description: 'New HTO Fulfillment pages',
    link: '/fullfilments/home-trial-orders'
  },
  {
    id: 'DynamicLensFlow',
    name: 'Dynamic lens flow',
    description: 'Use BE response to generate lens related steps for add/edit flows',
    link: '/'
  },
  {
    id: 'DynamicLensFlowDevMode',
    name: 'Enable Dev Mode for dynamic lens flow',
    description: 'Requires Dynamic Lens Flow to be enabled',
    link: '/'
  }
];

export const useFlags = () => {
  const key = ref(new Date().getTime());

  const flagOn = (flag: TFlag) => {
    Cookies.set(flag.id, 'true');
    key.value = new Date().getTime();
  };

  const flagOff = (flag: TFlag) => {
    Cookies.remove(flag.id);
    key.value = new Date().getTime();
  };

  const isFlagOn = (id: string) => {
    const result = Cookies.get(id);
    return !!result && result === 'true';
  };

  return {
    flags,
    flagOn,
    flagOff,
    isFlagOn,
    key
  };
};

export const isFlagOn = (id: string) => useFlags().isFlagOn(id);

export const show404 = (message: string = '', router?: VueRouter) => {
  if (isAdminApplication()) {
    if (router) {
      router.replace('/404');
      throw new Error(message);
    } else if (window) {
      window.location.href = '/404';
    }
  } else if ((process as any)?.client) {
    if ((window as any)?.$nuxt) {
      (window as any)?.$nuxt.error({
        statusCode: StatusCodes.NOT_FOUND,
        message
      });
    } else {
      window.location.href = '/';
    }
  }
};

export const disallowOnProduction = (flagId?: string, router?: VueRouter) => {
  const isEnabled = flagId ? isFlagOn(flagId) : true;

  if (isPROD() || !isEnabled) {
    show404(`Feature ${flagId || ' '} is not allowed on production`, router);
  }
};

// TODO: New fulfillment pages specific stuff, remove when all fulfillment pages are rebuilt
export const fulfillmentTypeToFlagMapping = {
  [FulfillmentTypes.HOME_TRIAL]: 'NewHTOFulfillments'
};
export const fulfillmentTypeReleasedMapping = {
  [FulfillmentTypes.ACCESSORY]: true
};

export const isNewFulfillmentType = (type: FulfillmentTypes) =>
  fulfillmentTypeReleasedMapping[type] ||
  (!!fulfillmentTypeToFlagMapping[type] && isFlagOn(fulfillmentTypeToFlagMapping[type]));
