import { BaseEyeTest, Measurement } from '~/api/schema/eyeTest';
import Address from '~/api/schema/address';
import Order from '~/api/schema/order';
import { CardDetails, RefundPaymentList, RefundRequestList, SalesChannel } from '~/api/schema/payment';
import MedicalRecord from '~/api/schema/medicalRecord';
import {
  Archive,
  ISODate,
  ISODateTime,
  ObjectReference,
  RequirementReasons,
  RequirementValues
} from '~/api/schema/common';
import {
  ExchangeOptions,
  OrderItemRxOnHoldReasons,
  OrderItemStatuses,
  OrderItemWarrantyChoices,
  RemakeOptions
} from '~/api/schema/orderItemConstants';
import { FulfillmentDeliveryTypes } from '~/api/schema/fulfillmentConstants';
import OrderItem, { UIOrderItemAdditionalCharge, UIOrderItemDiscount } from '~/api/schema/orderItem';
import { PaymentMethods } from '~/api/schema/paymentConstants';
import { DiscountItemTypes, EditOrderExchangeRequestTypes } from '~/api/schema/orderConstants';
import { Administrator } from '~/api/schema/account';
import {
  ExecutedSimulationStatus,
  FrameReplacementAllowReasons,
  OrderSimulationTypes,
  ParentRXInfoRequirementReason,
  SimulationPaymentTypes
} from './orderSimulationConstants';

export type RemovedOrderItem = {
  id?: number;
  status?: OrderItemStatuses;
  sequence?: number;
  notes?: string;
  details?: Array<string>; //TODO probably some set list of values
};

export type RemovedOrderItems = Array<RemovedOrderItem>;

export type MeasurementAttributes = Partial<Measurement>;

export type EyeTestAttributes = BaseEyeTest & {
  prescription_attributes?: {
    expiry_date?: string | null;
    pupillary_distance?: string | number | null;
    eye_measurements_attributes?: Array<MeasurementAttributes>;
    use_hcl_heights?: boolean;
  };
};

export type GiftCardOrderItemAttributes = ObjectReference & {
  asset_media_id?: number;
  temp_media_id?: number;
  recipient?: {
    name: string;
    email: string;
  };
  sender: {
    name: string;
  };
  message_header: string;
  message_body: string;
  gift_card_option_id: number;
  initial_balance: number;
  to_be_sent_at?: ISODate;
  request_id?: string;
};

export type OrderItemAttributes = ObjectReference &
  ({
    id?: number;
    delivery_type?: FulfillmentDeliveryTypes;
    location_id?: number;
    order_item_discounts_attributes?: Array<UIOrderItemDiscount>;
    eye_test_attributes?: Partial<EyeTestAttributes>;
    order_item_lens_transition_colour_attributes?: { transition_colour: string };
    order_item_additional_charges_attributes?: Array<UIOrderItemAdditionalCharge>;
    lens_tint_absorption_percentage?: number;
    request_id?: string;
    is_eye_test_booked?: boolean;
    rx_on_hold_reason?: OrderItemRxOnHoldReasons;
    collection_configuration_name?: string;
    added_via_quiz?: boolean;
    selection_flow_data?: string;
  } & Partial<GiftCardOrderItemAttributes>);

export interface PaymentSimulation {
  type: SimulationPaymentTypes;
  amount: number;
  change_returned?: number;
  currency_code: string;
  sales_channel_id: string;
  payment_link?: string;
}

export interface RefundSimulation {
  id: string;
  amount: number;
  currency_code: string;
}

export type GoodwillGestureRequest = {
  reason: string;
  value: number;
};

export type ExchangeOrderItemAttributes = OrderItemAttributes & {
  exchange_order_item_id?: number;
  exchange_order_item_status?: OrderItemStatuses;
  exchange_types?: Array<EditOrderExchangeRequestTypes>;
  exchange_details?: Array<ExchangeOptions | RemakeOptions | OrderItemWarrantyChoices>;
  exchange_notes?: string;
};

export type OrderSimulationParams = Partial<{
  notify_user: boolean;
  sales_channel_id: number;
  removed_order_items: RemovedOrderItems;
  order_items_attributes: Array<OrderItemAttributes>;
  exchange_order_items_attributes: Array<ExchangeOrderItemAttributes>;
  eye_test_attributes: Partial<EyeTestAttributes>;
  shipping_address: Partial<Address>;
  payments: Array<PaymentSimulation>;
  refunds: Array<RefundSimulation>;
  goodwill_gesture_discount: GoodwillGestureRequest;
  //this field is used only in the HUB draft order simulation checkout
  voucher_usage?: {
    voucher_code: string;
    id?: number;
  };
  gift_card_or_code: Array<{ code: string; sequence: number }>;
  removed_voucher_usages_ids?: Array<number>;
  save_payload?: boolean;
  simulation_type: OrderSimulationTypes;
  delivery_type?: FulfillmentDeliveryTypes;
  // below must be provided for "orderless" simulation
  currency_code?: string;
  country_market_code?: string;
  // below is for Web for guest user info
  user?: {
    email: string;
    first_name: string;
    last_name: string;
  };
}>;

export interface RefundOption {
  id: string;
  type: PaymentMethods;
  total_refund_amount: number;
  card_details?: CardDetails;
  payments: RefundPaymentList;
}

export type RefundOptionList = Array<RefundOption>;

export type SimulatedOrderItem = OrderItem & ObjectReference & { id: string | number };

export type SimulationOrder = Order & {
  refund_requests: RefundRequestList | null;
  refund_options: RefundOptionList;
  order_items: Array<SimulatedOrderItem>;
  last_simulation_payload?: OrderSimulationParams | null;
  draft_info?: {
    draft_last_saved: ISODateTime | null;
    draft_saved_by: Administrator | null;
  };
};

export enum PDTypes {
  NEAR = 'near',
  INTERMEDIATE = 'intermediate',
  DISTANCE = 'distance'
}

export enum ParentRXWarnings {
  LENS_TYPE = 'Lens type',
  PRISM_CHARGE = 'Prism charge',
  LENS_INDEX = 'Lens index'
}

interface PDValues {
  right: number;
  left: number;
}

export enum SharedInfoActions {
  REMOVE_EXCHANGE_REASON = 'remove exchange reason'
}
export enum HeightsInfoActions {
  INPUT_EXISTING_HEIGHTS = 'input existing heights',
  APPLY_NULL_HEIGHTS = 'apply null heights',
  USER_INPUTS_NEW_HEIGHTS = 'user inputs new heights',
  USER_INPUTS_HEIGHTS = 'user inputs heights',
  HCL_ALLOWED = 'HCL allowed',
  HCL_NOT_ALLOWED = 'HCL not allowed',
  HCL_DISPLAYED = 'HCL displayed'
}
export enum PDInfoActions {
  APPLY_NULL_PD = 'apply null pd',
  APPLY_USER_PD = 'apply user pd',
  APPLY_ORIGINAL_PD = 'apply original pd',
  DISPLAY_PD_CHANGED_ERROR_MESSAGE = 'display pd changed error message'
}
export enum BVDInfoActions {
  USER_INPUTS_BVD = 'user inputs BVD',
  APPLY_NULL_BVD = 'apply null BVD',
  USER_INPUTS_NEW_BVD = 'user inputs new BVD',
  INPUT_EXISTING_BVD = 'input existing BVD'
}

type MeasurementRequirementsInfo<T> = {
  actions?: Array<T> | null;
  status: RequirementValues | null;
  reason: RequirementReasons | string;
};

type PDMeasurementRequirementsInfo<T> = MeasurementRequirementsInfo<T> & {
  new_pd?: PDValues | null;
  original_pd?: PDValues | null;
  pd_type?: PDTypes | null;
};

export interface RxWarningItemsBlock {
  message: string;
  remove_exchange_details: Array<RemakeOptions | ExchangeOptions | OrderItemWarrantyChoices> | null;
}

export type EyeMeasurementRequirements = {
  heights_info?: MeasurementRequirementsInfo<HeightsInfoActions | SharedInfoActions>;
  pupillary_distance_info?: PDMeasurementRequirementsInfo<PDInfoActions | SharedInfoActions>;
  back_vertex_distance_info?: MeasurementRequirementsInfo<BVDInfoActions | SharedInfoActions>;
};

export enum OrderItemFulfilmetnOptionType {
  OFF_THE_SHELF = 'Off the shelf',
  ORDER_FROM_LAB = 'Order from lab'
}

export type OrderItemFulfilmentOption = {
  type: OrderItemFulfilmetnOptionType | string;
  position: number;
  disabled: boolean;
};

export type OrderItemFulfilmentInfo = {
  fulfillment_options: Array<OrderItemFulfilmentOption>;
  display_string: string | null;
};

export type OrderItemSimulationInformation = {
  //string non-persisted, number persisted
  id: string | number;
  available_for_immediate_pick_up?: boolean;
  available_for_lens_swap?: boolean;
  parent_rx_info?: {
    requires_new_prescribed_rx: boolean;
    parent_rx_requirement_reason: ParentRXInfoRequirementReason;
  };
  eye_measurement_requirements?: EyeMeasurementRequirements;
  exchange_details_configuration?: {
    swap_for_clear_or_sun_lenses: {
      is_available: boolean;
      glass_id: number | null;
    };
    change_frame_colour: RequirementValues;
  };
  request_id?: string;
  fulfillment_info: OrderItemFulfilmentInfo;
};

export type GoodwillGestureOption = {
  reason: string;
  max_value: number;
};

export type FrameReplacementInfo = {
  minimum_frame_replacement_price: number;
  is_allowed: boolean;
  reasons?: Array<FrameReplacementAllowReasons>;
};

export type SimulationInfoParentRXWarnings = Record<`S-${number}` | `${number}`, Array<ParentRXWarnings>>;

export type GiftCardOrCodeInstance = {
  type: DiscountItemTypes;
  display: string;
  //Gift Card only
  last_four_digits?: string;
};

export type SimulationInfo = {
  order_items: Array<OrderItemSimulationInformation>;
  order_items_price: number;
  total_price_of_the_order: number;
  maximum_card_refund_amount: number;
  next_item_is_discountable: boolean;
  outstanding_balance: number;
  requires_eye_test: boolean;
  new_product_allowed: boolean;
  warnings: {
    refund_amounts: Array<any>; //TODO
    removed_order_items: Array<any>; //TODO
    pwyc_discounts: Array<any>; //TODO
    sales_channel: string;
    order_item_additions: Array<{
      message: string;
    }>;
    voucher_usage?: Array<string>;
    gift_card_or_code: Record<string, Array<string>>;
    parent_rx?: SimulationInfoParentRXWarnings;
  };
  medical_records: Partial<MedicalRecord>;
  simulated_shipping_address: Address & Archive;
  is_eligible_for_goodwill_gesture: boolean;
  goodwill_gesture_options: Array<GoodwillGestureOption>;
  frame_replacement_info?: FrameReplacementInfo;
  gift_card_or_code_instances: Record<string, GiftCardOrCodeInstance>;
};

type SimulationResult = {
  order: SimulationOrder;
  additional_information: SimulationInfo;
};

export type SimulationUpdateData = {
  result: SimulationResult;
  simulationParams: OrderSimulationParams;
};

export interface ExecutedSimulation {
  id: number;
  status: ExecutedSimulationStatus;
  messages: Record<string, Array<string>>;
  sales_channel: SalesChannel;
}

export default SimulationResult;
