import { AxiosPromise } from 'axios';
import request, { prepareParams, requestOrCache } from './utils/request';
import {
  LensOrder,
  LensOrderGlazingType,
  LensOrdersList,
  LensOrdersQueryFilters,
  LensOrdersStats,
  LensOrderStatuses,
  LensOrderSuppliers
} from './schema/lensOrders';
import Media from './schema/media';
import { ResponseStatus } from './schema/common';

export interface LensOrdersBulkUpdates {
  lens_orders: Array<{
    id: string | number;
    status?: LensOrderStatuses;
    notes?: string;
    glazing_type?: LensOrderGlazingType;
    supplier?: LensOrderSuppliers;
  }>;
}

export function fetchLensOrdersStats(): AxiosPromise<{ stats: LensOrdersStats }> {
  return request({
    url: '/lens_orders/stats',
    method: 'get'
  });
}

export const getLensOrders = (params: LensOrdersQueryFilters): AxiosPromise<LensOrdersList> => {
  return requestOrCache({
    url: `/lens_orders?${prepareParams(params)}`,
    method: 'get'
  });
};

export const bulkUpdateLensOrders = (
  params: LensOrdersBulkUpdates
): AxiosPromise<{
  lens_orders: LensOrder[];
  meta?: {
    frame_trace_emails_count?: number;
  };
}> => {
  return requestOrCache({
    url: `/lens_orders/bulk_update`,
    method: 'put',
    data: params
  });
};

export const bulkUploadLensOrdersMedia = (
  uploadForm: FormData
): AxiosPromise<Record<string, { status: ResponseStatus; message: string; media: Media[] }>> => {
  return requestOrCache({
    url: `/lens_orders/media/bulk_upload`,
    method: 'post',
    data: uploadForm
  });
};

export function exportLensOrders(query: { lens_order_ids: Array<string | number>; supplier: LensOrderSuppliers }) {
  return request({
    url: `/lens_orders/export?${prepareParams(query)}`,
    method: 'get',
    responseType: 'blob'
  });
}

export function updateLensOrderMedia(
  uploadForm: FormData,
  lensOrderId: number | string,
  mediaId: number | string
): AxiosPromise<{ medium: Media; affected_parent_object_id_medium_id: Record<string, number> }> {
  return request({
    url: `/lens_orders/${lensOrderId}/media/${mediaId}`,
    method: 'put',
    data: uploadForm
  });
}

export function createLensOrderMedia(
  uploadForm: FormData,
  lensOrderId: number | string
): AxiosPromise<{ medium: Media; affected_parent_object_id_medium_id: Record<string, number> }> {
  return request({
    url: `/lens_orders/${lensOrderId}/media`,
    method: 'post',
    data: uploadForm
  });
}

export function deleteLensOrderMedia(
  lensOrderId: number | string,
  mediaId: number | string
): AxiosPromise<{ affected_parent_object_id_medium_id: Record<string, number> }> {
  return request({
    url: `/lens_orders/${lensOrderId}/media/${mediaId}`,
    method: 'delete'
  });
}
